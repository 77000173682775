import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const ShowNavbar = ({children}) => {
  const location =useLocation();
  const [ showNavBar, setShowNavBar] = useState(false);
  useEffect( () => {
    if(location.pathname === '/control'){
      setShowNavBar(false);
    }else if(location.pathname === '/Controlctegery'){
      setShowNavBar(false);
    }else if(location.pathname === '/editcategery/:id'){
      setShowNavBar(false);
    }else if(location.pathname === '/Mainwork'){
      setShowNavBar(false);
    }else if(location.pathname === '/ControlBrand'){
      setShowNavBar(false);
    }else if(location.pathname === '/addphototobrand'){
      setShowNavBar(false);
    }else if(location.pathname === '/addlinkbrand'){
      setShowNavBar(false);
    }else if(location.pathname === '/addvideobrand'){
      setShowNavBar(false);
    }else if(location.pathname === '/ControlDecor'){
      setShowNavBar(false);
    }else if(location.pathname === '/addphototodecor'){
      setShowNavBar(false);
    }else if(location.pathname === '/addlinkdecor'){
      setShowNavBar(false);
    }else if(location.pathname === '/addvideoodecor'){
      setShowNavBar(false);
    }else if(location.pathname === '/Controlweb'){
      setShowNavBar(false);
    }else if(location.pathname === '/addphototoapp'){
      setShowNavBar(false);
    }else if(location.pathname === '/addlinkapp'){
      setShowNavBar(false);
    }else if(location.pathname === '/addvideooapp'){
      setShowNavBar(false);
    }else if(location.pathname === '/ControlSocial'){
      setShowNavBar(false);
    }else if(location.pathname === '/addphototosocial'){
      setShowNavBar(false);
    }else if(location.pathname === '/addlinksocial'){
      setShowNavBar(false);
    }else if(location.pathname === '/addvideoosocial'){
      setShowNavBar(false);
    }else if(location.pathname === '/Controlmedia'){
      setShowNavBar(false);
    }else if(location.pathname === '/addphototomedia'){
      setShowNavBar(false);
    }else if(location.pathname === '/addlinkmedia'){
      setShowNavBar(false);
    }else if(location.pathname === '/addvideoomedia'){
      setShowNavBar(false);
    }else if(location.pathname === '/ControlPhotography'){
      setShowNavBar(false);
    }else if(location.pathname === '/addphototoPhotography'){
      setShowNavBar(false);
    }else if(location.pathname === '/addlinkPhotography'){
      setShowNavBar(false);
    }else if(location.pathname === '/addvideooPhotography'){
      setShowNavBar(false);
    }else if(location.pathname === '/Controloffset'){
      setShowNavBar(false);
    }else if(location.pathname === '/addphototooffset'){
      setShowNavBar(false);
    }else if(location.pathname === '/addlinkoffset'){
      setShowNavBar(false);
    }else if(location.pathname === '/addvideoooffset'){
      setShowNavBar(false);
    }else if(location.pathname === '/Controlgift'){
      setShowNavBar(false);
    }else if(location.pathname === '/addphototogift'){
      setShowNavBar(false);
    }else if(location.pathname === '/addlinkgift'){
      setShowNavBar(false);
    }else if(location.pathname === '/addvideoogift'){
      setShowNavBar(false);
    }else if(location.pathname === '/Controldoor'){
      setShowNavBar(false);
    }else if(location.pathname === '/addphototodoor'){
      setShowNavBar(false);
    }else if(location.pathname === '/addlinkdoor'){
      setShowNavBar(false);
    }else if(location.pathname === '/addvideoodoor'){
      setShowNavBar(false);
    }
    else if(location.pathname === '/Controlmainhome'){
      setShowNavBar(false);
    }
    else if(location.pathname === '/toveiwphoto'){
      setShowNavBar(false);
    }
    else if(location.pathname === '/addphoto'){
      setShowNavBar(false);
    }
    
    else if(location.pathname === '/addphoto'){
      setShowNavBar(false);
    }
    else if(location.pathname === '/toviewteamwork'){
      setShowNavBar(false);
    }
    
    else if(location.pathname === '/getphotoemail'){
      setShowNavBar(false);
    }
    
    else if(location.pathname === '/addphotoabout'){
      setShowNavBar(false);
    }
    
    else if(location.pathname === '/getphotoaboat'){
      setShowNavBar(false);
    }
    else if(location.pathname === '/addphotoaemail'){
      setShowNavBar(false);
    }

    else{
      setShowNavBar(true);
    }

    
  },[location])

return(

  <>
    {
      showNavBar && children
    }
  </>    
)

 
};

export default ShowNavbar;
