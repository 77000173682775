import React, { useEffect } from 'react';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {Container , Button, Col} from 'react-bootstrap';
import Swal from 'sweetalert2';

const Controlmainhome = () => {
  const [open, setIsOpen] = useState(false);

    return(

      <>
      <div className="services" id="services" >
        <div className="container services-container ">
            <div className="service">
                <br/>
                <br/>
                <h2> Image Of Work </h2>
                <div>
                <Link
                    className="link"
                    to = {`/toveiwphoto`}
                    onClick={() => {
                    setIsOpen(false);
                    }}
                    >
                    <Button   variant="danger">View</Button>  
                </Link>
              </div>
            </div>

            <div className="service">
                <br/>
                <br/>
                <h2> Control TeamWork </h2>
                <div>
                <Link
                    className="link"
                    to = {`/toviewteamwork`}
                    onClick={() => {
                    setIsOpen(false);
                    }}
                    >
                    <Button   variant="danger">View</Button>  
                </Link>
              </div>
            </div>
        </div>
      </div>
   
      </>
    )


}

export default Controlmainhome; 

