
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import Sum from './Sum';
import { Check } from '@mui/icons-material';
import './details.css';
import logo from '../../../assets/8dab4f88a129879185f50e7ed22aa8a2whats-removebg-preview (1).png';



const Custmize = (props) => {
  
  function link() {
    //  👇️// redirect to external URL
     
      window.location.replace('https://wa.link/fiolo6');
   
     return null;
   }

  const [band, setBand]= useState([]);
   let lang;
   let lang2;
  const [detailsw, setDetailsw]= useState([]);
  const [desabdle, setDesable] = useState(0); 
  const { t, i18n } = useTranslation();
  const [ sum, setSum] = useState(0);
  const [ nam, setNam] = useState('0');
  const [ n, setN] = useState(0);
  const [ select, setSelect] = useState('');
  const [ select1, setSelect1] = useState('');
  const [ select2, setSelect2] = useState('');

  let { id } =useParams();
  let { name } =useParams();
    let style = 'buy';
    
   let arrName=[];
   arrName = [...new Set(nam.split(','))];
   
   let arrName2=[];
   arrName2 = [...new Set(select.split(','))];
   let arrName3=[];
   arrName3 = [...new Set(select1.split(','))];
   let arrName4=[];
   arrName4 = [...new Set(select2.split(','))];

  useEffect(()=>{
      fetch1();
      fetch();
  }, [id]) //desabdle
 const fetch1 = async() => {
  try{
    // fetchwdetail
    
      const { data } = await axios.get(`https://backend.dasta.net/api/auth/filterwdetailBelongToCat/${id}`);
          console.log(data);
          setDetailsw(data);
      
  }catch(err){
      console.log("error");
      console.error(err);
  }      
}  


const fetch = async() => {
  try{
      const { data } = await axios.get(`https://backend.dasta.net/api/auth/filterbandBelongCate/${id}`);
          console.log(data);
          setBand(data);
      
  }catch(err){
      console.log("error");
      console.error(err);
  }      
}

    const sty = () =>{
        style = "Selected";
    } 
    console.log(typeof(desabdle));





    {/************************************************ */}
    if( i18n.language =='en'){
      lang= detailsw.map((detailsw)=>{  
        return(
            <>
            <tr>
            <th>{detailsw.name}</th>
            <th>{detailsw.details}</th>
            <th>{detailsw.number}</th>
            <th>{/*detailsw.price*/}$</th>
            <th>
                
            <input type='checkbox'
                   value={'checked'}
                   style={{width: '20px',height:'20px'}}
                   onClick={()=>{
                    
                 // setSelect(1);
                  //  setSum(detailsw.price + sum );
                    setDesable(detailsw.id);
                     if(arrName.includes(detailsw.id.toString())){
                      setNam(nam.replace(detailsw.id, '0'))
                      setSum(sum - detailsw.price)
                      setN(detailsw.price-detailsw.price - 1 + n);  
                      setSelect(select.replace(detailsw.name, '0'))
                      setSelect1(select1.replace(detailsw.details, '0'))
                      setSelect2(select2.replace(detailsw.number, '0'))
                     }else{
                    setNam(detailsw.id + "," + nam); 
                    setN(detailsw.price-detailsw.price + 1 + n);
                    setSum(detailsw.price + sum );
                    setSelect(select + "," + " " + detailsw.name )
                    setSelect1(select1 + "," + " ***" + detailsw.details )
                    setSelect2(select2 + "," + " *" + detailsw.number )

                     }
                   }}
                   
                   />
            </th>
            <br/>
            </tr>
            </>
        )
    })
    }else{
      lang= detailsw.map((detailsw)=>{  
        return(
            <>
            <tr>
            <th>    
            <input type='checkbox'
                   value={'checked'}
                   style={{width: '20px',height:'20px'}}
                   onClick={()=>{
                    
                 // setSelect(1);
                  //  setSum(detailsw.price + sum );
                    setDesable(detailsw.id);
                     if(arrName.includes(detailsw.id.toString())){
                      setNam(nam.replace(detailsw.id, '0'))
                      setSum(sum - detailsw.price)
                      setSelect(select.replace(detailsw.name, '0'))
                      setN(detailsw.price-detailsw.price - 1 + n);  
                      setSelect1(select1.replace(detailsw.details, '0'))
                      setSelect2(select2.replace(detailsw.number, '0'))


                     }else{
                    setNam(detailsw.id + "," + nam); 
                    setN(detailsw.price-detailsw.price + 1 + n);
                    setSelect(select + ","+ " " + detailsw.name )
                    setSum(detailsw.price + sum );
                    setSelect1(select1 + "," + "**** " + detailsw.details )
                    setSelect2(select2 + "," + "* " + detailsw.number )
                     }
                   }}
                   
                   />
            </th>         
            <th>$</th>
            <th>{detailsw.number}</th>
            <th>{detailsw.details_ar}</th>
            <th>{detailsw.name_ar}</th>
            <br/>
            </tr>
            </>
        )
    })

    }
    if( i18n.language =='en'){
      lang2=
      <tr>
          <td> Band</td>
          <td> Details</td>
          <td> Number</td>
          <td> Price</td>
          <td> buy </td>
      </tr>
    }
    else{
      lang2=
      <tr>
        <td> شراء</td>
        <td> سعر</td>
        <td> الرقم </td>
        <td> تفاصيل</td>
        <td> بند </td>
    </tr> 
    }

  return (
    <>
   <div className="plans">
        <div className="general-background-image">
        <div className="back">
            <h2>Custmize Plans</h2>
            <Link className="link back-link" to="/">
            {t('home')}
            </Link>
        </div>
        </div>
    </div>


     <div className="plans">
       <div className="webapp-plans container">
        <div style={{
          color:'white',
          textAlign:'center',
          margin: '0px auto',
        }}>
          <h2>
            {name} plans
          </h2>
        </div>
        <table>     
            
        {
          lang2
        }
        

        {
            lang
        }

       
        </table>
        </div>
      </div>
      <div className='total'>
        <br/>
        <br/>
        <h3>Total Price : {sum}  {t('dola')}</h3>
        <br/>
        <Link to ={`/sendmeaasage/${arrName2}/${sum}/${arrName3}/${arrName4}`}>
            <button hidden ={n<5} className='btn btn4 btn5'> {t('Abu')}</button>
        </Link>
        <br/>
        <br/>
        <br/>
      </div>
      <br/>
      <br/>


      <img src={logo} alt='logo' 
            style={{ 
              width :'100px',
              height:'100px',
              position: 'fixed',
              zIndex: '10',
              top: '85%',
              right:'5px',
              cursor:'pointer',
              
             }}
             onClick={()=>link()}
             />  
       

      </>
  );

};

export default Custmize;
