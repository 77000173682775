import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';



const Getsonsection  = () => {
    const [open, setIsOpen] = useState(false);
    const [data, setData] = useState([]);
    let { id } = useParams();
    const [data1, setData1] = useState([]);
  const { t, i18n } = useTranslation();



  
    useEffect(() => {
        section();
        upsection();

     
     }, []);

     const section = () => {
      fetch(`https://backend.dasta.net/api/auth/getsonbelongtosection/${id}`)
     .then((res)=>res.json())
     .then((data)=>{
       console.log(data);
       setData(data);
       });
     }
     const upsection = () => {
        fetch(`https://backend.dasta.net/api/auth/filtersectionwokautomatic/${id}`)
       .then((res)=>res.json())
       .then((data)=>{
         console.log(data);
         setData1(data);
         });
       }


       const deleteSection = (idd) => {         
        fetch(`https://backend.dasta.net/api/auth/deletesonsectionwokautomatic/${idd}`,{
            method: "DELETE"
        })
        .then((res) => res.json())
       }
    return( 
<>
<br/>
<br/>
{
        data1.map((data1)=>{
            return(
                <>
                <h2 style={{
                    textAlign: 'center',
                    color:'white',
                }}>{data1.name}</h2>
                </>
            )
        })
    }
    <br/>
    <br/>
<Link
      className="link"
      to = {`/addsonsectionwok/${id}`}
      onClick={() => {
      setIsOpen(false);
      }}
  >
  <Button className='buttonn' variant="success">Add New Section</Button>
  </Link>

  <div className="services" id="services">
      <div className="container services-container">
        {
            data.map((data)=>{
                return(
                    <>

                            
                        <div className="service" >
                            <br/>
                            <br/>
                            <h2 style={{
                                textAlign:'center',
                                width: '250px',
                                 wordWrap:'break-word',

                                 }}>{data.name}</h2>

                            <div className="gallery" >
                                <img
                                src={"https://backend.dasta.net/brand/"+ data.photo  }

                                alt=""
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    borderRadius: '10px',
                                }}
                                />
                            </div>


                            <Link
                            className="link"
                            to = {`/viewsonsectionall/${data.id}`}
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            >
                                <button className ='btn' > View </button>
                            </Link>

                            
                            
                            <Link
                            className="link"
                            to = {`/editsonsectionwok/${data.id}`}
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            >
                                <button className ='btn' > Edit Name  </button>
                            </Link>
                            
                            <Link
                            className="link"
                            to = {`/editsonsectionwokphoto/${data.id}`}
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            >
                                <button className ='btn' > Edit photo  </button>
                            </Link>

                            <Link
                            className="link"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            >
                                <button className ='btn' onClick={ () => deleteSection(data.id)}> Delete  </button>
                            </Link>
                        </div>
                    </>
                )
            })
        }
        </div>
         </div>
        </>

    )

}
export default Getsonsection;