import React, { useEffect } from 'react';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import './Controlcategery.css';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {Container , Button, Col} from 'react-bootstrap';
import Swal from 'sweetalert2';



const Controlcategery = () => {
    const [categers, setCategers]= useState([]);
    const [open, setIsOpen] = useState(false);

   useEffect(()=>{
    getAllCate()
    
   }, [])

   const getAllCate = () =>{
    fetch('https://backend.dasta.net/api/auth/fetchcate')
    .then((res)=>res.json())
    .then((data)=>{
      console.log(data);
      setCategers(data);
      })
   }
{/** 
   const EditCate = cateId =>{
    fetch(`https://backend.dasta.net/api/auth/updatecate/${cateId}`,{
        method: " POST"
    })
    .then((res)=>res.json())
   }*/}

   const deleteCate = (cateId) => {
    Swal.fire({
        
        title:`Are you sure delete ?`,
        showCancelButton: true 
    }).then((data) =>{
        if(data.isConfirmed){
            fetch(`https://backend.dasta.net/api/auth/deletacate/${cateId}`,{
                method: "DELETE"
            })
            .then((res) => res.json())
            getAllCate();
        }
    });

   }
    return(
        <>

     <div className="services" id="services">
        <div className="container services-container ">
            {
                categers.map( (categers) => {
                    return( 
                        <li key ={categers.id} >
                            <div className="service">
                              <br/>
                              <br/>
                                { categers.name }
                                <div>
                                <Button   variant="danger" onClick={()=>deleteCate(categers.id)}>Delete</Button> 
                                <Link
                                    className="link"
                                    to =  {`/editcategery/${categers.id}`}
                                    onClick={() => {
                                    setIsOpen(false);
                                    }}
                                 >
                                    <Button   variant="danger">Edit</Button>

                                 </Link>
                                <Link
                                    className="link"
                                    to = {`${categers.name}/${categers.id}`}
                                   // to = {`Controlproduct/${categers.id}`}
                                    onClick={() => {
                                    setIsOpen(false);
                                    }}
                                 >
                                  <Button   variant="danger">View</Button>  
                                </Link>                              
                                </div>
                            </div>
                        </li>
                    ); 
                })
            }        
        </div>
    </div>
    <Link
        className="link"
        to = '/addcategery'
        onClick={() => {
        setIsOpen(false);
        }}
    >
    <Button className='buttonn' variant="success">Add New Section</Button>
    </Link>

        </>
    )
}
export default Controlcategery;