import { useEffect } from 'react';
import { useState } from 'react';
import './Addcatetegery.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Adddetailsdis = ()=>{
    const [name, setName] = useState('');
    const [name_ar, setName_ar] = useState('');
    const [details_id, setDetails_id] = useState(0);
    const [quantity, setQuantity] = useState('');
    const [details, setDetails] = useState('');
    const [details_ar, setDetails_ar] = useState('');
    const [price, setPrice] = useState(0);
   // const [id, setId] = useState(0);


    let { bandId } = useParams();
    let navigt = useNavigate();


    const  formSubmit = (e)=>{
            e.preventDefault()
            axios.post("https://backend.dasta.net/api/auth/adddetail",{
                name,
                name_ar,
                details_id : bandId,
                quantity,
                details,
                details_ar,
                price,

            }).then((data)=>{
                navigt(-1);
            })
        }  
    return (
    
        
        <form onSubmit={formSubmit}>
            <label for="fname" >Name Of Band:</label>
            <input type="text" id="fname" name="fname" onChange={(e)=>setName(e.target.value)}/>
           
            <label for="fname" >Name Of Band:Arabic</label>
            <input type="text" id="fname" name="fname" onChange={(e)=>setName_ar(e.target.value)}/>
            
            <label for="lname">Details:</label>
            <textarea name="postContent" rows={4} cols={40} onChange={(e)=>setDetails(e.target.value)}></textarea>
          
          
            <label for="lname">Details:Arabic</label>
            <textarea name="postContent" rows={4} cols={40} onChange={(e)=>setDetails_ar(e.target.value)}></textarea>
           
          
           {/* <input type="text" id="lname" name="lname"  onChange={(e)=>setDetails(e.target.value)}/>*/}
            <label for="lname">quantity:</label>
            <input type="text" id="lname" name="lname"  onChange={(e)=>setQuantity(e.target.value)}/>
            <label for="lname">Price:</label>
            <input type="text" id="lname" name="lname"  onChange={(e)=>setPrice(e.target.value)}/>
            
            {/* <label for="lname">PLEASE ENTER THIS NUMBER {bandId}</label>
            <input type="text" id="lname" name="lname"  onChange={(e)=>setDetails_id(e.target.value)}/>
           
            */}
            <button type='submit' >add</button>
         </form>
    )
}
export default Adddetailsdis;