
import React, { useEffect } from 'react';
import { useState } from 'react';
import "./getphoto.css"
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import {Container , Button, Col} from 'react-bootstrap';




const Getphotowork = () =>{
    const [ data, setData] = useState([]);
    const [open, setIsOpen] = useState(false);


    useEffect(() => {
        {/**me */} fetch("https://backend.dasta.net/api/auth/getphoto")
        .then((res)=>res.json())
        .then((data)=>{
          console.log(data);
          setData(data);
          });
       // setCollection([...new Set(data.map((data1) => data1.galleryType))]);
      }, []);



      const deletea = (bandId) => {
            fetch(`https://backend.dasta.net/api/auth/deltephoto/${bandId}`,{
                method: "DELETE"
            })       
       }
    return(
        <>
        <br/>
        <br/>
        <br/>
        <br/>
         <Link
        className="link"
        to = '/addphoto'
        onClick={() => {
        setIsOpen(false);
        }}
        >
        <Button className='buttonn' variant="success">Add New Image of Works</Button>
        </Link>
        <br/>
        <br/>
        <br/>
          <div className=" galleries-container">
          
          {
            data.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/home_work/"+ data.name  }

                  alt=""
                />
                
                <Link
                className="link"
                to = {`/changephoto/${data.id}`}
                onClick={() => {
                setIsOpen(false);
                }}
                >
                <Button className='buttonn change' variant="success">Change This Photo </Button>
                </Link>
                <button className='btn1' onClick={()=>deletea(data.id)}>Delete</button>

              </div>  
              )
            })
          } 
        </div>
          

        </>
    )
} 
export default Getphotowork;