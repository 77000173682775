import React, { useEffect, useState } from 'react';
import image1 from '../../../assets/one.jpg';
import image2 from '../../../assets/two.jpg';
import image3 from '../../../assets/three.jpg';
import image4 from '../../../assets/four.jpg';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../../assets/logo.jpg';
import { useTranslation } from 'react-i18next';
import { FaArrowCircleRight, FaArrowCircleLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
 import Slider from 'react-slick';
 import "slick-carousel/slick/slick.css"; 
 import "slick-carousel/slick/slick-theme.css";
 import './homeGallery.css';


//  import { Swiper, SwiperSlide} from 'swiper/react';
//  import { FreeMode} from 'swiper';
//  import 'swiper/css';
//  import 'swiper/css/free-mode';
const HomeGallery = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: true
        }
      },
    ]}

  const { t, i18n } = useTranslation();
{/*
  const data = [
    {
      image: logo,
      galleryType: 'Logo',
      link2: t('logo'),
    },
    {
      image: image2,
      galleryType: 'programming',
      link2: t('mobile'),
    },
    {
      image: image3,
      galleryType: 'markting',
      link2: t('seo'),
    },
    {
      image: image4,
      galleryType: 'markting',
      link2: t('markting'),
    },
    {
      image: image1,
      galleryType: 'markting',
      link2: t('markting'),
    },
  ];
*/}
  {/*me*/}const [ data, setData] = useState([]);
  const [model, setModel] = useState(false);
  const [temimgSrc, setTemImgSrc] = useState('');
  const getImage = (imageSrc) => {
    setTemImgSrc(imageSrc);
    setModel(true);
  };
  const [galleryData, setGalleryData] = useState(data);
  const [n, setN] = useState(0);
  {/** me */}
  const [current, setCurrent] = useState(n);
  const length  = data.length;
  const nextSlide = () =>{
    setCurrent( current === length - 1 ? 0 : current + 1)
  }
  const prevtSlide = () =>{
    setCurrent( current === 0 ? length - 1 : current - 1)
  }
  useEffect(() => {
    {/**me */} fetch("https://backend.dasta.net/api/auth/getphoto")
    .then((res)=>res.json())
    .then((data)=>{
      console.log(data);
      setData(data);
      });
   // setCollection([...new Set(data.map((data1) => data1.galleryType))]);
  }, []);
  const galleryFilter = (itemData) => {
    const filterPictures = data.filter((data) => data.galleryType === itemData);
    setGalleryData(filterPictures);
  };
  const navigate = useNavigate();
  return (
    <div>
      <div className="home-galleries" id="gallery">
        <h2
          className="general-header"
          style={{ marginTop: '50px', cursor: 'pointer' }}
          onClick={() => navigate('/work')}
        >
          {t('Aclient')}
        </h2>
        <hr className="general-hr" />
        <div className=" galleries-container" style={{width:'70%', margin:'0 auto'}}>
          <div className={model ? 'model open' : 'model'}>
            <FaArrowCircleLeft className='left'  style={{color:'black'}} onClick={prevtSlide}/>
            {/* <img src={temimgSrc} alt="" /> */}
            {
              data.map((slide, index)=>{
                return(
                   <>   
                   <div className={ current === index ? ' slide active' : 'slide '}>
                      {index === current && (
                          <img src={ "https://backend.dasta.net/home_work/"+ slide.name } style={{
                            maxWidth: '70%',
                          }}/>
                        )} 
                   </div>
                  </>
                )
              })
            }
            <FaArrowCircleRight className='right' style={{color:'black'}} onClick={nextSlide}/>

            <CloseIcon onClick={() => setModel(false)} />
          </div>



        </div>

        
      </div>

      <div className='abdo'>
      <Slider {...settings} >
           {
            data.map((data, index)=>{
              return(
                <div className="cad"  >
                <div className='cad-top'>
                <img
                style={{width:'250px',height:'250px', margin:'0 auto'}}
                src={"https://backend.dasta.net/home_work/"+ data.name  }

                  alt=""
                   onClick={() => {getImage( "https://backend.dasta.net/home_work/"+data.name  );
                   setCurrent(index)
                   
                  }}
                />
              </div>  
              </div>
              )
            })
          } 
          </Slider>
          </div>
    </div>
  );
};

export default HomeGallery;

// {/**

// galleryData.map((data, index) => {
//             return (
//               <div className="gallery" key={index}>
//                 <img
//                   src={data.image}
//                   alt=""
//                   onClick={() => getImage(data.image)}
//                 />

//                 {/* <Link className=" gallery-link">{data.link2}</Link> */}
//              //   </div>
//                // );
//               //})
// //*/}*