import React, { useState } from 'react';
import './homeStrategy.css';
import { MdArrowBackIosNew } from 'react-icons/md';
import { MdArrowForwardIos } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


const HomeStratgy = () => {
  const { t, i18n } = useTranslation();

  

  const data = [t('strategy1'), t('strategy2'), t('strategy3'), t('strategy4')]
  const [counter1, setCounter1] = useState(t('BeSeen'));
  const [counter, setCounter] = useState(data[0]);
  
  if(i18n.language=="en"){
  
    }else{

    }

  const { pathname } = useLocation(); 
 useEffect(()=>{
  window.scrollTo(0, 0)
  handleSlider2(0)
  handleSlider(0)

  
 }, [pathname , t])

  const handleSlider = (index) => {
    setCounter(data[index]);
  };
  const handleSlider2 = (index) => {
    
    if(index===0){
      setCounter1(t('BeSeen'));
    }else if(index===1){
      setCounter1(t('Bingou'));
    }else if(index===2){
      setCounter1(t('buildTust'));

    }else if(index===3){
      setCounter1(t('staAhead'));
    }

  };

  return (
    <div className="str">
      <div className="strategy-background">
        <h2 className="strategy-header">
          {t('our')} <span>{t('strategy')}</span>
        </h2>
        <div className="description">
        <h5>{counter1} </h5>
          <p>{counter}</p>
          <div className="slider-point">
            {data.map((data, index) => {
              return (
                <>
                <h1
                  key={index}
                  onClick={() =>{handleSlider2(index);handleSlider(index)}}
                  className="point"
                ></h1>

</>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeStratgy;
