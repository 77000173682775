
import React, { useEffect } from 'react';
import { useState } from 'react';
import "./getphoto.css"
import { Link, json } from 'react-router-dom';
import {Container , Button, Col} from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { use } from 'i18next';
import { useParams } from 'react-router-dom';


const  Addphototeamwork = () =>{
    const [name, setName] = useState('');
    const [ photos_id, setPhotos_id] = useState(0);
    let navigt = useNavigate();
    let { id } = useParams();  // id of name of member to belong to 
    const handleImage =(e)=>{
        setName(e.target.files[0]) 
    }
    const  formSubmit = (e)=>{
        e.preventDefault()
        const formData = new FormData()
        formData.append('name', name)
        formData.append('photos_id', photos_id)
 
        // axios.post('https://backend.dasta.net/api/auth/addphotonewteam',formData,{
        //     photos_id: id,
        // }).then((res)=>{
        //     console.log(res)
        // }).then((data)=>{
        //     navigt(-1);
        // })

        axios({
            method: 'post',
            url: 'https://backend.dasta.net/api/auth/addphotonewteam',
            data: formData,
        //    photos_id,
        }).then((res)=>{
            console.log(res)
        }).then((data)=>{
            navigt(-1);
        })

      
    }  

    return(
        <>
        <form onSubmit={formSubmit}>
            <input type="file" name="file" onChange={(e)=>setName(e.target.files[0])}/>
             
             <label for="lname">PLEASE ENTER THIS NUMBER {id}</label>
            <input type="text" id="lname" name="lname"  onChange={(e)=>setPhotos_id(e.target.value)}/>
         
            <button type='submit' >submit</button>
        </form>
        </>
    )

}
export default Addphototeamwork;