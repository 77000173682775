
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const Controldetails = (props) => {
    const [details, setDetails]= useState([]);
    const [detailsw, setDetailsw]= useState([]);

    const [open, setIsOpen] = useState(false);


    //let {bandId} =useParams();
    let  id  =  props.band.id;
    let a = 15 ;
    let typeofmodule;
    let typeoftable;
    
   
    useEffect(()=>{
        //  function fetch(){
        //     const req1 = axios.get(`https://backend.dasta.net/api/auth/filterdetailBelongToBand/${id}`);
        //     const req2 = axios.get(`https://backend.dasta.net/api/auth/filterwdetailBelongToBand/${id}`);
        //     axios.all([req1,req2])
        //     .then(axios.spread((data)=>setDetails({'type1':res[1],'type2':res[2]})))

        //  }
      
        fetch1();
        fetch2();

      
    }, [id])
   const fetch1 = async() => {
    try{
        const { data } = await axios.get(`https://backend.dasta.net/api/auth/filterdetailBelongToBand/${id}`);
            console.log(data);
            setDetails(data);
        
    }catch(err){
        console.log("error");
        console.error(err);
    }      
}
    
    const fetch2 = async() => {
        try{
            const { data } = await axios.get(`https://backend.dasta.net/api/auth/filterwdetailBelongToBand/${id}`);
                console.log(data);
                setDetailsw(data);
            
        }catch(err){
            console.log("error");
            console.error(err);
        }      
    }

    
    const deletedetails = (detailsId) => {
        Swal.fire({
            
            title:`Are yoe sure delete ?`,
            showCancelButton: true 
        }).then((data) =>{
            if(data.isConfirmed){
                fetch(`https://backend.dasta.net/api/auth/deletadetail/${detailsId}`,{
                    method: "DELETE"
                })
                .then((res) => res.json())
              //  getAllCate();
            }
        });
    
       }
       const deletedetailsw = (detailsId) => {
        Swal.fire({
            
            title:`Are yoe sure delete ?`,
            showCancelButton: true 
        }).then((data) =>{
            if(data.isConfirmed){
                fetch(`https://backend.dasta.net/api/auth/deletawdetail/${detailsId}`,{
                    method: "DELETE"
                })
                .then((res) => res.json())
              //  getAllCate();
            }
        });
    
       }

     
        {
        a = details.map(details=>{
            return(
                <>
                    {details.details_id}

                </>
            )
        })}
        { a = Number(a)} // return number but is NAN 
        if (isNaN(a)){

            typeoftable =  <tr>
            <td> Band</td>
            <td> Details</td>
            <td> Quantity</td>
            <td> Price</td>
            <td> تفاصيل</td>
            <td> البند</td>
            <td> Options</td>


        </tr> 
        }else{
            typeoftable = 
            <tr>
                <td> Band</td>
                <td> Details</td>
                <td> Number</td>
                <td> Price</td>
                <td> تفاصيل</td>
                <td> البند</td>
                <td> Options</td>


            </tr>
           

        }
        
                 
        
        
       
        
       
      
    return (
      <> 
      <table>     
        {
            typeoftable
        }   
        {
            detailsw.map(detailsw=>{
                return(
                    <>
                    <tr>
                    <td>{detailsw.name}</td>
                    <td>{detailsw.details}</td>
                    <td>{detailsw.number}</td>
                    <td>{detailsw.price} $</td> 
                    <td>{detailsw.details_ar} </td> 
                    <td>{detailsw.name_ar} </td> 

                    <td>
                        <button className='btn1' onClick={()=>deletedetailsw(detailsw.id)}>Delete</button>
                        <Link
                            className="link"
                            to =  {`/editdetailswithoutdis/${detailsw.id}`}
                            onClick={() => {
                            setIsOpen(false);
                            }}
                        >
                        <button className='btn1'>Edit</button>
                        </Link>
                    </td>
                    <br/>
                    </tr>
                  
                    </>
                  
                )
            })

            
        }


{
            details.map(details=>{
                return(
                    <>
                    <tr>
                    <td>{details.name}</td>
                    <td>{details.details}</td>
                    <td>{details.quantity}</td>
                    <td>{details.price} $</td>
                    <td>{details.details_ar} </td>
                    <td>{details.name_ar} </td>

                    <td>
                        <button className='btn1' onClick={()=>deletedetails(details.id)}>Delete</button>
                        <Link
                            className="link"
                            to =  {`/editdetailsdis/${details.id}`}
                            onClick={() => {
                            setIsOpen(false);
                            }}
                        >
                        <button className='btn1'>Edit</button>
                        </Link>
                    </td>
                    <br/>
                    </tr>
                  
                    </>
                  
                )
            })

            
        }
      </table>
      </>
    )
};

export default Controldetails;

{/**
details.map(details=>{
                  return(
                      <>
                      <tr>
                      <td>{details.name}</td>
                      <td>{details.details}</td>
                      <td>{details.number}</td>
                      <td>{details.price}</td>

                      <td>
                          <button className='btn1' onClick={()=>deletedetails(details.id)}>Delete</button>
                          <Link
                              className="link"
                              to =  {`/editdetailswithoutdis/${details.id}`}
                              onClick={() => {
                              setIsOpen(false);
                              }}
                          >
                          <button className='btn1'>Edit</button>
                          </Link>
                      </td>
                      <br/>
                      </tr>
                    
                      </>
                    
                  )
              })

*/}