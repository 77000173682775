import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { DefaultPlayer as Video } from 'react-html5video';
import { MdOutlineSlowMotionVideo } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import 'react-html5video/dist/styles.css';




const Getsonsectionvideoo = () =>{
 
    const [ data, setData] = useState([]);
    const [open, setIsOpen] = useState(false);
    const [runVideo, setIsRunVideo] = useState(false);
    const { t, i18n } = useTranslation();
    let {id}  = useParams();




    useEffect(() => {
         fetch(`https://backend.dasta.net/api/auth/getsonbelongtosectionvideo/${id}`)
        .then((res)=>res.json())
        .then((data)=>{
          console.log(data);
          setData(data);
          });
      }, []);
    return(
        <>
        <br/>
        <br/>
        <br/>
        <br/>

        <div className='up-Avideo' >
            {
                data.map((data)=>{
                    return(
                        <>
                            <div className='A-video'>
                                <Video style={{height:'220px', maxWidth: '100%'}}>
                                    <source src={"https://backend.dasta.net/brand/"+ data.photo} type='video/webm'/>
                                </Video>
                                <div>
                                    <h5 style={{
                                  textAlign: 'center',
                                  color: 'whitesmoke',
                                  width:'100%',
                                  wordWrap:'break-word',
                                  padding: '10px',
                                }}>
                                        {data.name}
                                    </h5>
                                </div>
                            </div>
                        </>
                    )
                })
            }
        </div>
        </>
    )
}

export default Getsonsectionvideoo;
