import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Getlinkbrand1 from './Getlinkbrand1';
import Getvideobrand1 from './Getvideobrand1';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';



const Getphotobrand1 = ()=>{

  //   const [ data, setData] = useState([]);
  // const { t, i18n } = useTranslation();

  //   const [open, setIsOpen] = useState(false);


  // const [model, setModel] = useState(false);
  // const [temimgSrc, setTemImgSrc] = useState('');
  // const getImage = (imageSrc) => {
  //   setTemImgSrc(imageSrc);
  //   setModel(true);
  // };
  // const [galleryData, setGalleryData] = useState(data);
  // const [collection, setCollection] = useState([]);
  

  // const galleryFilter = (itemData) => {
  //   const filterPictures = data.filter((data) => data.galleryType === itemData);
  //   setGalleryData(filterPictures);
  // };


  //   useEffect(() => {
  //        fetch("https://backend.dasta.net/api/auth/getphotoourworkbrand")
  //       .then((res)=>res.json())
  //       .then((data)=>{
  //         console.log(data);
  //         setData(data);
  //         });
  //     }, []);

  //   return(
  //       <>

  //     <div className="general-background-image gal">
  //       <div className="back">
  //         <h2>{t('Branding')}</h2>
  //         <Link className="link back-link" to="/">
  //           {t('home')}
  //         </Link>
  //       </div>
  //     </div>


          
  //       <div className=" galleries-container">
  //         <div className={model ? 'model open' : 'model'}>
  //           <img src={temimgSrc} alt="" />
  //           <CloseIcon onClick={() => setModel(false)} />
  //         </div>
  //         {
  //           data.map((data)=>{
  //             return(
  //               <div className="gallery" >
  //               <img
  //               src={"https://backend.dasta.net/brand/"+ data.name  }

  //                 alt=""
  //                 onClick={() => getImage("https://backend.dasta.net/brand/"+ data.name)}

  //               />
  //             </div>  
  //             )
  //           })
  //         } 
  //       </div>

  //       {
  //           < Getlinkbrand1/>
  //       }
  //       {
  //           < Getvideobrand1/>
  //       }
        
  //       </>
  //   )
}
export default Getphotobrand1;