import React, { useState } from 'react';
import './teamWork.css';
import { Link } from 'react-router-dom';
import abdulrahman1 from '../../../assets/abdulrahman.png';
import abdulrahman2 from '../../../assets/abdulrahman1.png';
import abdulrahman3 from '../../../assets/abdulrahman2.png';
import abdulrahman4 from '../../../assets/abdulrahman3.png';
import abdulrahman5 from '../../../assets/abdulrhman4.png';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';


const TeamWork = () => {
  let a = 0 ;
  const { t, i18n } = useTranslation();
  const [image, setImage] = useState([]);
  {/* me*/} 
  const [ photo, setPhoto] =  useState([]);
  const [ name, setName] =  useState([]);

  useEffect(() => {
    {/**me */}  fetch(`https://backend.dasta.net/api/auth/filterdetailBelongToTeam/${image}`)
    .then((res)=>res.json())
    .then((data)=>{
      console.log(data);
      setPhoto(data);
      });
      fetch("https://backend.dasta.net/api/auth/getnewteam")
    .then((res)=>res.json())
    .then((data)=>{
      console.log(data);
      setName(data);
      });
  }, [image]);


  return (
    <div className="team-work " style={{marginTop:'100px'}}>
      <h2 style={{marginBottom:"20px"}} className="general-header">{t('team')}</h2>
      <hr className="general-hr" />
      <div className="container team-work-container">
        <div className="team-work-persons">

          {
            name.map((name)=>{
              return(
                <>
                <Link

                onClick={() => setImage(name.id)}
                  className="link team-link team-work-one"
                >
                 <span>{name.name}</span>
                </Link>
                </>
              )
            })
          }
       {/* 
          <Link
            onClick={() => setImage('abdulrahman1')}
            className="link team-link team-work-one"
          >
            <span>{t('ahmed')}</span> {t('ali')}
          </Link>
          <Link
            onClick={() => setImage('abdulrahman2')}
            className="link team-link  team-work-two"
          >
            <span>{t('muhammad')}</span> {t('ali')}
          </Link>
          <Link
            onClick={() => setImage('abdulrahman3')}
            className="link team-link team-work-three"
          >
            <span>{t('yaman')}</span> {t('ahmed')}
          </Link>
          <Link
            className="link  team-link team-work-four"
            onClick={() => setImage('abdulrahman4')}
          >
            <span>{t('hossam')}</span> {t('ahmed')}
          </Link>
          <Link
            className="link team-link team-work-five"
            onClick={() => setImage('abdulrahman5')}
          >
            <span>{t('ahmed')}</span> {t('yaman')}
        </Link>*/}
        </div>
        <div className="team-work-images"  >
       
          {
           photo.map((photo=>{
            return(
              <>

             <img src ={"https://backend.dasta.net/teamPhotos/" + photo.name}   max-width={350} height={350} />

           
              {/* <img src ={"https://backend.dasta.net/teamPhotos/" + photo.name}   max-width={350} height={350} /> */}

             
              </>
            )
          }))
          }
        </div>
      </div>
    </div>
  );
};

export default TeamWork;
