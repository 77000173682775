import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import logo from '../../../assets/8dab4f88a129879185f50e7ed22aa8a2whats-removebg-preview (1).png';
import i18next from 'i18next';



const Getsectionwok1  = () => {
    const [open, setIsOpen] = useState(false);
    const [data, setData] = useState([]);
    let lang;

  
    useEffect(() => {
        section();
     
     }, []);

     const section = () => {
      fetch("https://backend.dasta.net/api/auth/getsectionwokautomatic")
     .then((res)=>res.json())
     .then((data)=>{
       console.log(data);
       setData(data);
       });
     }

     
     function link() {
        //  👇️// redirect to external URL
         
          window.location.replace('https://wa.link/fiolo6');
       
         return null;
       }
       {/*********************************** */}
       if(i18next.language == "en"){
        lang=
        data.map((data)=>{
            return(
                <>     
                    <div className="service" >
                        <br/>
                        <br/>
                        <h2 style={{
                            textAlign:'center',
                            width: '250px',
                             wordWrap:'break-word',

                             }}>{data.name}</h2>

                        <div className="gallery" >
                            <img
                            src={"https://backend.dasta.net/brand/"+ data.photo  }

                            alt=""
                            style={{
                                width: '150px',
                                height: '150px',
                                borderRadius: '10px',
                            }}
                            />
                        </div>
                                        
                        <Link
                        className="link"
                        to = {`/showsonsection/${data.id}`}
                        onClick={() => {
                            setIsOpen(false);
                            }}
                            >
                            <h2> See Gallery  </h2>
                        </Link>
    
                    </div>
                </>
            )
        })
       }else{
        lang=
        data.map((data)=>{
            return(
                <>     
                    <div className="service" >
                        <br/>
                        <br/>
                        <h2 style={{
                            textAlign:'center',
                            width: '250px',
                             wordWrap:'break-word',

                             }}>{data.name}</h2>

                        <div className="gallery" >
                            <img
                            src={"https://backend.dasta.net/brand/"+ data.photo  }

                            alt=""
                            style={{
                                width: '150px',
                                height: '150px',
                                borderRadius: '10px',
                            }}
                            />
                        </div>
                                        
                        <Link
                        className="link"
                        to = {`/showsonsection/${data.id}`}
                        onClick={() => {
                            setIsOpen(false);
                            }}
                            >
                            <h2> المزيد  </h2>
                        </Link>
    
                    </div>
                </>
            )
        })
       }
    return( 
<>

        {
            lang
        }

        
      <img src={logo} alt='logo' 
            style={{ 
              width :'100px',
              height:'100px',
              position: 'fixed',
              zIndex: '10',
              top: '85%',
              right:'5px',
              cursor:'pointer',
              
             }}
             onClick={()=>link()}
             />  
        </>

    )

}
export default Getsectionwok1;