import React from 'react';
import './contact.css';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { CgMail } from 'react-icons/cg';
import { TbMessageCircle } from 'react-icons/tb';
import { IoLocation } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import contact1 from '../../assets/Contact Us copy.jpg';
import logo from '../../assets/8dab4f88a129879185f50e7ed22aa8a2whats-removebg-preview (1).png';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Contact = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation(); 
 useEffect(()=>{
  window.scrollTo(0, 0)
 }, [pathname])
 function link() {
  //  👇️// redirect to external URL
   
    window.location.replace('https://wa.link/fiolo6');
 
   return null;
 }

  return (
    <div clssName='conA'>
      <div className="general-background-image" style={{backgroundImage:`linear-gradient( 0deg, hsl(218, 26%, 14%, 0.8), hsl(218, 26%, 14%, 0.8) ), url("${contact1}")`}} >
        <div className="back">
          <h2>{t('contactus')}</h2>
          <Link className="link back-link" to="/">
            {t('home')}
          </Link>
        </div>
      </div>

      <div className="contacts" id="contact">
        <h2 className="general-header">{t('contactusCapital')}</h2>
        <hr className="general-hr" />
        <div className="container contact-container">
          <div className="contact">
            <BsFillTelephoneFill
              style={{ fontSize: '40px', color: 'var(--color-yellow)' }}
            />
            <a
              href="https://api.whatsapp.com/send?phone=201012237297"
              target="blank"
              className="link contact-link yellow"
            >
              {t('phon')}
            </a>
            <p>01012237297</p>
          </div>
          <div className="contact">
            <CgMail
              style={{ fontSize: '40px', color: 'var(--color-orange)' }}
            />
            <a
              href="mailto:dastateam@gmail.com"
              className="link contact-link orange"
            >
              {t('emailContact')}
            </a>
            <p>info@dasta.net</p>
          </div>
          <div className="contact">
            <TbMessageCircle style={{ fontSize: '40px', color: 'green' }} />
            <a
              href="https://www.facebook.com/DastaProduction"
              target="blank"
              className="link contact-link green"
            >
              {t('messanger')}
            </a>
            <p>{t('dastaCap')}</p>
          </div>
          <div className="contact">
            <IoLocation style={{ fontSize: '40px', color: 'green' }} />
            <a
              href="https://maps.app.goo.gl/2WpvNzXn7tThn3Gt6"
              target="blank"
              className="link contact-link green"
            >
              {t('location')}
            </a>
            <p>{t('egypt')}</p>
          </div>
        </div>
      </div>

      
      <img src={logo} alt='logo' 
            style={{ 
              width :'100px',
              height:'100px',
              position: 'fixed',
              zIndex: '10',
              top: '85%',
              right:'5px',
              cursor:'pointer',
              
             }}
             onClick={()=>link()}
             />
    </div>
    
  );
};

export default Contact;
