import React from 'react';
import './land.css';
import { useTranslation } from 'react-i18next';

const Land = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="home">
      <div className="container ">
        <div className="home-left">
          <div className="top">
            <span className="hi">D</span>
            <span className="five">A</span>
          </div>
          <div className="bottom">
            <span className="de">S</span>
            <span className="ee">TA</span>
          </div>
          <p className="home-left-text">{t('logoService')}</p>
        </div>
        <div className="home-right">
          <h1>{t('Hi')}</h1>
          <h3 className="name1">{t('with')}</h3>
          <h3 className="name2">
            {t('agency')} {t('dasta')}{' '}
          </h3>
        </div>
      </div>
    </div>
  );
};
export default Land;
