import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Button } from 'react-bootstrap';



const Getsonsectionlink1 = (props) => {
    const [open, setIsOpen] = useState(false);
    const [data, setData] = useState([]);
    let {id} = useParams();

  
    useEffect(() => {
        fetch(`https://backend.dasta.net/api/auth/getsonbelongtosectionlink/${id}`)
       .then((res)=>res.json())
       .then((data)=>{
         console.log(data);
         setData(data);
         });
     }, []);
    return( 
        <div className="service" style={{width: "80%" , margin: '50px auto', textAlign:'center'}}>
          <br/>
          {
            data.map((data)=>{
                return(
                    <>
                    <ul>
                        <li>
                            <Link className='link'
                             to={`${data.name}`}
                             style={{
                                wordWrap:'break-word',
                                width: '90%',
                                textAlign:'center',
                                wordBreak:'break-all',
                                padding: '5px',
                             }}
                            >
                                {data.name}
                            </Link>
                            <div>
                                <h6 style={{
                                  textAlign: 'center',
                                  color: 'whitesmoke',
                                  width:'95%',
                                  wordWrap:'break-word',
                                  padding: '10px'
                                }}>
                                    {data.photo}
                                </h6>
                            </div>
                        </li>
                    </ul>

                    </>
                )
            })
          }
        </div>
    )

}
export default Getsonsectionlink1;