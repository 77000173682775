//import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './Controlproduct1.css';





import React from 'react';
const Kareem = (props) => {
  const [ dis, setDis] = useState([]);
  const [ total, setTotal] = useState([]);
  const [open, setIsOpen] = useState(false);



  useEffect(()=>{
    getDis();
    gettotal();
   }, [props.band.id])
 
  const getDis = () =>{
    const fetch1 = async() => {
        try{
            const { data } = await axios.get(`https://backend.dasta.net/api/auth/filterdescoundBelongToBand/${props.band.id}`);
            console.log(data);
            setDis(data);
        }catch(err){
            console.log("error");
            console.error(err);
        }
    }
    fetch1();
}

const gettotal = () =>{
    const fetch1 = async() => {
        try{
            const { data } = await axios.get(`https://backend.dasta.net/api/auth/filterdesumBelongToBand/${props.band.id}`);
            console.log(data);
            setTotal(data);
        }catch(err){
            console.log("error");
            console.error(err);
        }
    }
    fetch1();
}

const deletedes = (id) => {
 
            fetch(`https://backend.dasta.net/api/auth/deletadescound/${id}`,{
                method: "DELETE"
            })
            .then((res) => res.json())
        }
  
        const deletetotal = (id) => {
 
            fetch(`https://backend.dasta.net/api/auth/deletadesum/${id}`,{
                method: "DELETE"
            })
            .then((res) => res.json())
        }

   


    return(
      <>
{
        total.map(dis=>{
            return(
                <>
                <br/>
                     <h2>The Total Price: { dis.sum} </h2>
                     <Link
                        className="link"
                        to = {`/edittotal/${dis.id}`}
                        onClick={() => {
                        setIsOpen(false);
                        }}
                    >
                        <h2>
                            EDIT total   ?? 
                        </h2>
                    </Link>

                    <Link
                        className="link"
                        onClick={() => {
                        setIsOpen(false);
                        deletetotal(dis.id);
                        }}
                    >
                        <h2>
                            Delete total   ?? 
                        </h2>
                    </Link>
       
                 <br/>
                </>
            )
        })
      }


      {
        dis.map(dis=>{
            return(
                <>
                <br/>
                     <h2>the total after  {dis.details} : { dis.price} </h2>
                     <Link
                        className="link"
                        to = {`/editdis/${dis.id}`}
                        onClick={() => {
                        setIsOpen(false);
                        }}
                    >
                        <h2>
                            EDIT DESCOUND   ?? 
                        </h2>
                    </Link>

                    <Link
                        className="link"
                        onClick={() => {
                        setIsOpen(false);
                        deletedes(dis.id);
                        }}
                    >
                        <h2>
                            Delete DESCOUND   ?? 
                        </h2>
                    </Link>
       
                 <br/>
                </>
            )
        })
      }
      </>
    )
    
}
export default Kareem;