import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Controlproduct1.css';


import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import Controldetails from './Controldetails';
//import Details from '../details/Details';
import Swal from 'sweetalert2';
import {Container , Button, Col} from 'react-bootstrap';
import Kareem from './Kareem';





const Controlproduct1 = () => {

    let { name } = useParams();
    let { id } =useParams();
    const [band, setBand]= useState([]);
    const [open, setIsOpen] = useState(false);

  
    useEffect(()=>{
     getBand();
    // getDis();
    }, [id])
  
    const getBand = () =>{
        const fetch = async() => {
            try{
                const { data } = await axios.get(`https://backend.dasta.net/api/auth/filterbandBelongCate/${id}`);
                    console.log(data);
                    setBand(data);
                
            }catch(err){
                console.log("error");
                console.error(err);
            }      
        }
        fetch();
    }
    const deleteBand = (bandId) => {
        Swal.fire({
            
            title:`Are you sure delete ?`,
            showCancelButton: true 
        }).then((data) =>{
            if(data.isConfirmed){
                fetch(`https://backend.dasta.net/api/auth/deletaband/${bandId}`,{
                    method: "DELETE"
                })
                .then((res) => res.json())
                getBand();
            }
        });
    
       }
   
    return(
        <>
              {
                    band.map( band=>{
                        return(
                            <>
                                <h3 style={{
                                            display: 'inline-block',
                                            color:'aliceblue',
                                            width:'75%',
                                            textAlign:"center",
                                            padding:'20px',
                                }}>
                                  {band.name}
                                </h3>
                                <button className='btn1' onClick={()=>deleteBand(band.id)}>Delete</button>
                                <Link
                                    className="link"
                                    to =  {`/editproduct/${band.id}`} 
                                    onClick={() => {
                                    setIsOpen(false);
                                    }}
                                 >
                                    <button className='btn2'>Edit</button>

                                 </Link>
                                <Link
                                    className="link"
                                    to =  {`/adddetails/${band.id}/${id}`} //add id
                                    onClick={() => {
                                    setIsOpen(false);
                                    }}
                                 >
                                    <button className='btn2'>Add details</button>

                                 </Link>
                                <hr />
                              {/* <Details band = { band } />**/}
                              {<Controldetails band = { band }/>}
                              <hr/>
                              { < Kareem band = { band }/>}

                            </>
                        )
                    })
                }
                <Link
                    className="link"
                    to = {`/addproduct/${id}`}
                    onClick={() => {
                    setIsOpen(false);
                    }}
                >
                 <Button className='btn' variant="success">Add New Package</Button>
                </Link>
          
        </>
    )
}
export default Controlproduct1;