import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Button } from 'react-bootstrap';



const Getsonsectionlink = (props) => {
    const [open, setIsOpen] = useState(false);
    const [data, setData] = useState([]);

  
    useEffect(() => {
        fetch(`https://backend.dasta.net/api/auth/getsonbelongtosectionlink/${props.id}`)
       .then((res)=>res.json())
       .then((data)=>{
         console.log(data);
         setData(data);
         });
     }, []);

     const delet = (idd) => {         
        fetch(`https://backend.dasta.net/api/auth/deleteworksectionsonControllerlink/${idd}`,{
            method: "DELETE"
        })
        .then((res) => res.json())
       }


    return( 
      
    <div className="services" id="services">
      <div className="container services-containerlink">
        <div className="service">
          <br/>
          <br/>
          <Link
            className="link"
            to = {`/addlinksonsection/${props.id}`}
            onClick={() => {
            setIsOpen(false);
            }}
          >
          <Button className='buttonn' variant="success">Add New Link</Button>
        </Link>
          {
            data.map((data)=>{
                return(
                    <>
                    <ul>
                        <li>
                            <Link className='link'>
                               {data.name}
                            </Link>
                            <div>
                                <h4 style={{
                                  textAlign: 'center',
                                  color: 'whitesmoke',
                                  width:'80%',
                                  wordWrap:'break-word',
                                  fontFamily:'cursive',
                                }}>
                                    {data.photo}
                                </h4>
                            </div>
                            <Link
                                className="link"
                                to = {`/changelinksonsection/${data.id}`}
                                onClick={() => {
                                setIsOpen(false);
                                }}
                            >
                            <Button className='buttonn' variant="success">Edit</Button>
                            </Link>
                            <Button onClick={()=>delet(data.id)}>delete</Button>
                        </li>
                    </ul>

                    </>
                )
            })
          }
        </div>
      </div>
    </div>
    )

}
export default Getsonsectionlink;