import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
//import './control.css';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
const Control = () => {
  const { t, i18n } = useTranslation();
  const [open, setIsOpen] = useState(false);
  const [active, setActive] = useState('home');
  return (
    <div>
    <div className="general-background-image ser">
      <div className="back">
        <h2>{t('Dashboard')}</h2>
        <Link className="link back-link" to="/">
          {t('home')}
        </Link>
      </div>
    </div>

    <div className="services" id="services">
      <div className="container services-container">
        <div className="service">
          <br/>
          <br/>
         <h2>Products</h2>
          <Link
          className="link"
          to = '/Controlctegery'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            To control Main Topic
          </Link>
        </div>
        <div className="service">
          <br/>
          <br/>
          <h2>Home</h2>
            <Link
            className="link"
            to = '/Controlmainhome'
            onClick={() => {
              setIsOpen(false);
            }}
            >
              To control Main Topic in home
            </Link>
        </div>

        <div className="service">
          <br/>
          <br/>
          <h2>Work</h2>
            <Link
            className="link"
            to = '/Mainwork'
            onClick={() => {
              setIsOpen(false);
            }}
            >
              To control Main in Our Work
            </Link>
        </div>


        <div className="service">
          <br/>
          <br/>
          <h2>change image about</h2>
            <Link
            className="link"
            to = '/getphotoaboat'
            onClick={() => {
              setIsOpen(false);
            }}
            >
              To control Main in Our image
            </Link>
        </div>

        <div className="service">
          <br/>
          <br/>
          <h2>change image email</h2>
            <Link
            className="link"
            to = '/getphotoemail'
            onClick={() => {
              setIsOpen(false);
            }}
            >
              To control Main in Our image
            </Link>
        </div>



      </div>
    </div>
  </div>

   
  );
};

export default Control;
