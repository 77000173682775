import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import Getsonsectionvideoo from '../sonsectionvideo/Getsonsectionvideoo';
import Getsonsectionlink1 from '../sonsectionlink/Getsonsectionlink1';
import { FaArrowCircleRight, FaArrowCircleLeft } from 'react-icons/fa';
import logo from '../../../assets/8dab4f88a129879185f50e7ed22aa8a2whats-removebg-preview (1).png';
import { useLocation } from 'react-router-dom';



const Getsonsectionphoto1 = ()=>{

    const [ data, setData] = useState([]);
    const [ data1, setData1] = useState([]);
    const [ topic, setTopic] = useState([]);
    const [open, setIsOpen] = useState(false);
    let { id } = useParams();
  const { t, i18n } = useTranslation();
  const [model, setModel] = useState(false);
  const [temimgSrc, setTemImgSrc] = useState('');
  const getImage = (imageSrc) => {
    setTemImgSrc(imageSrc);
    setModel(true);
  };
  const [galleryData, setGalleryData] = useState(data);
  const [current, setCurrent] = useState(0);
  const length  = data.length;
  const nextSlide = () =>{
    setCurrent( current === length - 1 ? 0 : current + 1)
  }
  const prevtSlide = () =>{
    setCurrent( current === 0 ? length - 1 : current - 1)
  }  

  const galleryFilter = (itemData) => {
    const filterPictures = data.filter((data) => data.galleryType === itemData);
    setGalleryData(filterPictures);
  };
  const { pathname } = useLocation(); 






    useEffect(() => {
         fetch(`https://backend.dasta.net/api/auth/getsonbelongtosectionphoto/${id}`)
        .then((res)=>res.json())
        .then((data)=>{
          console.log(data);
          setData(data);
          });
          sonsection();
  window.scrollTo(0, 0)

      }, [pathname]);
      const sonsection = () => {
        fetch(`https://backend.dasta.net/api/auth/filtersonsectionwokautomatic/${id}`)
       .then((res)=>res.json())
       .then((data)=>{
         console.log(data);
         setData1(data);
         });
       }

       function link() {
        //  👇️// redirect to external URL
         
          window.location.replace('https://wa.link/fiolo6');
       
         return null;
       }
    return(
        <>
        {
          data1.map((data1)=>{
            return(
              <>           
              <div className="general-background-image gal">
                <div className="back" >
                  <h2 style={{
                    width:'100%',
                    textAlign:'center',
                    }}>{data1.name}</h2>
                    <br/>
                  <Link className="link back-link" to="/">
                    {t('home')}
                  </Link>
                </div>
              </div>
              </>
            )
          })
        }
        <div className=" galleries-container">
          <div className={model ? 'model open' : 'model'}>
            <FaArrowCircleLeft className='left'  onClick={prevtSlide}/>
            {/* <img src={temimgSrc} alt="" /> */}
            {
              data.map((slide, index)=>{
                return(
                   <>   
                   <div className={ current === index ? ' slide active' : 'slide '}>
                       {index === current && (
                          <img src={"https://backend.dasta.net/brand/"+ slide.photo  } style={{
                            maxWidth: '80%',
                          }}/>  
                          
                        )} 
                 {index === current && (
                   <div>
                   <h6 style={{
                             textAlign: 'center',
                             color: 'whitesmoke',
                             width:'100%',
                             wordWrap:'break-word',
                             padding: '10px'
                           }}>
                       {slide.name}
                   </h6>
               </div>
                          
                        )} 
                
                   </div>
                  </>
                )
              })
            }
            <FaArrowCircleRight className='right'  onClick={nextSlide}/>

            <CloseIcon onClick={() => setModel(false)} />
          </div>
       {     data.map((data, index)=>{
              return(
                <div className="gallery" style={{
                  margin:'15px',
                }}>
                    <img
                    src={"https://backend.dasta.net/brand/"+ data.photo  }

                    alt=""
                     onClick={() => {getImage({/**"https://backend.dasta.net/brand/"+ data.photo */}); setCurrent(index)}}

                    />
                    <div>
                        <h6 style={{
                                  textAlign: 'center',
                                  color: 'whitesmoke',
                                  width:'100%',
                                  wordWrap:'break-word',
                                  padding: '10px'
                                }}>
                            {data.name}
                        </h6>
                    </div>
                </div>  
              )
            })
          } 
        </div>
        <Getsonsectionlink1/>
        <Getsonsectionvideoo/>

        <img src={logo} alt='logo' 
            style={{ 
              width :'100px',
              height:'100px',
              position: 'fixed',
              zIndex: '10',
              top: '85%',
              right:'5px',
              cursor:'pointer',
              
             }}
             onClick={()=>link()}
             />  
      
        </>
    )
}
export default Getsonsectionphoto1;