import React, { useState } from 'react';
import './homeVideo.css';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import vid from '../../../assets/Branding.mp4';
import { MdOutlineSlowMotionVideo } from 'react-icons/md';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const HomeVideo = () => {
  const { t, i18n } = useTranslation();
  const [runVideo, setIsRunVideo] = useState(false);
  return (
    <div className="home-video">
      <MdOutlineSlowMotionVideo
        className="video-icon"
        onClick={() => setIsRunVideo(true)}
      />
      <h2>{t('why')}</h2>
      <h5>{t('videoPren')}</h5>
      <CloseIcon
        className={`close-video ${runVideo ? 'show-video-close-icon' : ''}`}
        onClick={() => setIsRunVideo(false)}
      />
      {runVideo ? (
        <Video className={`video ${runVideo ? 'run' : ''}`}>
          <source src={vid}></source>
        </Video>
      ) : (
        ''
      )}
    </div>
  );
};

export default HomeVideo;
