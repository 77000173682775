import { t } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import logo from '../assets/logo copy.jpg'



const Thanks = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation(); 
  useEffect(()=>{
   window.scrollTo(0, 0)
  }, [pathname])
  return (
    <div style={{width:'100vw'}}>
      <img src={logo} style={{width:'180px',position:'relative',left:'50%',top:'119px',marginLeft:"-90px",borderRadius:'15px'}}/>
        <h1 style={{textAlign:'center',color:'white',margin:'150px 0', color:'#d2691e',fontSize:'xx-large'}}>
            {t('thanks')}
        </h1>
    </div>
  )
}

export default Thanks
