
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import Sum from './Sum';
import { Check } from '@mui/icons-material';
import './details.css';
import Collection from '../collection/Collection';
import Sendmessagewhats from '../../Sendmessagewhats';
import i18n from '../../../i18n';



const Details = (props) => {
  const [details, setDetails]= useState([]);
  const [detailsw, setDetailsw]= useState([]);
  const [open, setIsOpen] = useState(false);
  const [ sum, setSum] = useState(0);
  const [ dis, setDis] = useState([]);
  const [desabdle, setDesable] = useState(0);
  const [ total, setTotal] = useState([]);
  const [ name, setName] = useState([]);
  const [ nam, setNam] = useState('0');
  const [ arr, setArr]  = useState('');
  const { t, i18n } = useTranslation();
  const [ select, setSelect] = useState('');
  const [ select1, setSelect1] = useState('');
  const [ select2, setSelect2] = useState('');
  const [ select3, setSelect3] = useState('');




  





  //let {bandId} =useParams();
  let  id  =  props.band.id;
  let namPackage  = props.band.name;
  let val =props.val;
  let a = 15 ;
  let typeoftable;
  let showDiscound;
  let showBuy;
   let diss;
   let tot;
   let lang;
   let arrName=[];
   arrName = [...new Set(nam.split(','))];

   
   let arrName2=[];
   arrName2 = [...new Set(select.split(','))];

   let arrName3=[];
   arrName3 = [...new Set(select1.split(','))];


   let arrName4=[];
   arrName4 = [...new Set(select2.split(','))];


   let arrName5=[];
   arrName5 = [...new Set(select3.split(','))];


  useEffect(()=>{
      fetch1();
      fetch2();
      getDis();
      gettotal();

  }, [id])
 const fetch1 = async() => {
  try{
      const { data } = await axios.get(`https://backend.dasta.net/api/auth/filterdetailBelongToBand/${id}`);
          console.log(data);
          setDetails(data);
      
  }catch(err){
      console.log("error");
      console.error(err);
  }      
}
  const fetch2 = async() => {
      try{
          const { data } = await axios.get(`https://backend.dasta.net/api/auth/filterwdetailBelongToBand/${id}`);
              console.log(data);
              setDetailsw(data);
          
      }catch(err){
          console.log("error");
          console.error(err);
      }      
  }
  const getDis = () =>{
    const fetch1 = async() => {
        try{
            const { data } = await axios.get(`https://backend.dasta.net/api/auth/filterdescoundBelongToBand/${id}`);
            console.log(data);
            setDis(data);
        }catch(err){
            console.log("error");
            console.error(err);
        }
    }
    fetch1();
}


const gettotal = () =>{
  const fetch1 = async() => {
      try{
          const { data } = await axios.get(`https://backend.dasta.net/api/auth/filterdesumBelongToBand/${props.band.id}`);
          console.log(data);
          setTotal(data);
      }catch(err){
          console.log("error");
          console.error(err);
      }
  }
  fetch1();
}


   
      {
        a = details.map(details=>{
            return(
                <>
                    {details.details_id}

                </>
            )
        })
      }
      { a = Number(a)} // return number but is NAN 
      props.a(a)

      if (isNaN(a) & i18n.language =='en'){ 

          typeoftable =  <tr>
          <td> Band</td>
          <td> Details</td>
          <td> Quantity</td>
          <td> Price</td>
          <td> buy </td>
      </tr> 
      }else if(isNaN(a) & i18n.language =='ar'){
        typeoftable =  <tr>
        <td> شراء</td>
        <td> سعر</td>
        <td> الكميه </td>
        <td> تفاصيل</td>
        <td> بند </td>
    </tr> 
      }else if(!isNaN(a) & i18n.language =='ar'){
        typeoftable =  <tr>
        <td> شراء</td>
        <td> سعر</td>
        <td> الرقم </td>
        <td> تفاصيل</td>
        <td> بند </td>
    </tr> 
      }
      else{
          typeoftable = 
          <tr>
              <td> Band</td>
              <td> Details</td>
              <td> Number</td>
              <td> Price</td>
              <td> buy </td>
          </tr>
      }
      if (!isNaN(a)){
        showDiscound =            
         <>
         {
          dis.map(dis=>{
            return(
                  <>
                  <Link to ={`/sendmeaasage/${namPackage}/${dis.price}`}> 
                    <button className='btn btn4 btn5'>{t('Abu')}</button>
                  </Link>  
                </>
            )
              
        })
      }
         </>
    }

    if(isNaN(a)){
      showBuy = 
      <>
      <br/>
      <h3 style={{color:'#52adb8'}}>
        {t('Apice')}   {sum} {t('dola')}
        </h3>
      <br/>

        <Link to ={`/sendmeaasage/${arrName2}/${sum}/${arrName3}/${arrName4}`}  >
          
          <button className='btn btn4 btn5'>{t('Abu')}</button>
         </Link>
         <br/>
         </>

    }

    if(!isNaN(a)){
      tot = 
      <>
      {
          total.map(dis=>{
              return(
                  <>
                  <br/>
                      <h2 > {t('Apice')}:<span style={{color:'#52adb8'}}> { dis.sum} {t('dola')}</span> </h2>
                  </>
              )
          })
        }
      </>
    }
    if(!isNaN(a)){
      diss =
      <>
      {
          dis.map(dis=>{
            return(
                <>
      <br/>
      <br/>

                    <h3 >
                    {t('Adis')} :<span style={{color:'#ffb820'}}> {dis.price }  {t('dola')} </span> 
                    </h3>
                    
                    <br/>
      <br/>
      <br/>
                </>
            )
              
        })
      }
      </>
    }     




{/******************************************************* */}

if (!isNaN(a) & i18n.language =='en'){ 
  lang= detailsw.map(detailsw=>{  
                return(
                    <>
                    <tr>
                    <th>{detailsw.name}</th>
                    <th>{detailsw.details}</th>
                    <th>{detailsw.number}</th>
                    <th>{/*detailsw.price*/}$</th>
                    <th>
                    
                    <button hidden = {val}  className='btn btn4' onClick={()=>{
                      setSum(detailsw.price + sum );
                      setNam(detailsw.name+nam);
                      setArr(detailsw.name);
                       }}> {t('Abu')} </button>                     
                  
                    </th>

                    <br/>
                    </tr>
                    </>
                )
            })
  

}else if(!isNaN(a) & i18n.language =='ar'){
  lang= detailsw.map(detailsw=>{  
    return(
        <>
        <tr>
        <th>
        
        <button hidden = {val}  className='btn btn4' onClick={()=>{
          setSum(detailsw.price + sum );
          setNam(detailsw.name+nam);
          setArr(detailsw.name);
           }}> {t('Abu')} </button>                     
      
        </th>
        
        <th>$</th>
        <th>{detailsw.number}</th>
        <th>{detailsw.details_ar}</th>
        <th>{detailsw.name_ar}</th>
        <br/>
        </tr>
        </>
    )
})

}else if(isNaN(a) & i18n.language =='en'){
  lang=
  details.map(details=>{
      return(
          <>
          <tr>
          <td>{details.name}</td>
          <td>{details.details}</td>
          <td>{details.quantity}</td>
          <td>{details.price} $ </td>
          <th>
          <input type='checkbox'
                 value={'checked'}
                 style={{width: '20px',height:'20px'}}
                 onClick={()=>{
                 // setSum(details.price + sum );
                 // setNam(details.name + "," + nam); 
                  setDesable(details.id);
                // setArr(details.name);
                   if(arrName.includes(details.id.toString())){
                    //console.log('1')
                    setNam(nam.replace(details.id, '0'))
                  //  console.log( arrName )
                    setSum(sum - details.price)
                    setSelect(select.replace(details.name, '0'))
                    setSelect1(select1.replace(details.details, '0'))
                    setSelect2(select2.replace(details.quantity, '0'))


                   }else{
                   // console.log('0')
                  setNam(details.id + "," + nam); 
                  setSum(details.price + sum );
                  setSelect(select + "," + "*" + details.name )
                  setSelect1(select1 + "," + "***" + details.details )
                  setSelect2(select2 + "," + "*" + details.quantity )


                   }
                 }}
                 
                 />
          </th>
          <br/>
          </tr>
          </>
      )
  })     
}
else{
  lang=
  details.map(details=>{
      return(
          <>
          <tr>
          <th>
          <input type='checkbox'
                 value={'checked'}
                 style={{width: '20px',height:'20px'}}
                 onClick={()=>{
                 // setSum(details.price + sum );
                 // setNam(details.name + "," + nam); 
                  setDesable(details.id);
                // setArr(details.name);
                   if(arrName.includes(details.id.toString())){
                    //console.log('1')
                    setNam(nam.replace(details.id, '0'))
                  //  console.log( arrName )
                    setSum(sum - details.price)
                    setSelect(select.replace(details.name, '0'))
                    setSelect1(select1.replace(details.details, '0'))
                    setSelect2(select2.replace(details.quantity, '0'))



                   }else{
                   // console.log('0')
                  setNam(details.id + "," + nam); 
                  setSum(details.price + sum );
                  setSelect(select + ","+ "* " + details.name )
                  setSelect1(select1 + "," + " ***" + details.details )
                  setSelect2(select2 + "," + " *" + details.quantity )


                   }
                 }}
                 
                 />
          </th>
          <td>{details.price} $ </td>
          <td>{details.quantity}</td>
          <td>{details.details_ar}</td>
          <td>{details.name_ar}</td>
          <br/>
          </tr>
          </>
      )
  })     
 
}



{/*********************************************************** */}

  return (
    

     <div className="plans">
       <div className="webapp-plans container">
        <table>     
        {
            typeoftable
        }   
{/**heeeeeeee */}


        {
          lang
        }
      </table>

    
      <div className='total'>
                     {
                      showBuy
                     }                

      </div>
      <br/>
      <br/>

      <div className='total'>
        {
          tot
        }
      </div>

      <div  className='total'>
      {
        diss
      }

  { showDiscound}

       <div hidden>
       { <Sendmessagewhats sum = {sum}/> }
        <Sum sum ={sum}/>
        </div>

{/*     
        {nam}
        <Sum sum ={sum}/>

    
        {typeof(nam.split(','))}$$
        {nam}
        ___
        {arrName} */}

      </div>

      {/************************************************************************** */}

      {/************************************************************************** */}

        
  {/*

         <table>
           <tr>
             <th>Band</th>
              <th>Details</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Buy</th>
            </tr>
            <tr>
              <td>Web site</td>
              <td>word press + host + free domain renewal</td>
              <td>1</td>
              <td>5000</td>
              <td>
                <button className="btn">Buy</button>
              </td>
            </tr>
            <tr>
              <td>Web store</td>
              <td>Dynamic + host + free domain</td>
              <td>1</td>
              <td>15000</td>
              <td>
                <button className="btn">Buy</button>
              </td>
            </tr>
            <tr>
              <td>Mobile App</td>
              <td>Flutter Android + IOS</td>
              <td>1</td>
              <td>15000</td>
              <td>
                <button className="btn">Buy</button>
              </td>
            </tr>
            <tr>
              <td>APP Store</td>
              <td>Flutter Android + IOS</td>
              <td>1</td>
              <td>25000</td>
              <td>
                <button className="btn">Buy</button>
              </td>
            </tr>
    </table>*/}

        </div>
      </div>
  );
};

export default Details;
