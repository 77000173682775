import React from 'react';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import image from '../../../assets/img_6-600x600.jpg';
import './homeContact.css';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
const HomeContact = () => {
  const { t, i18n } = useTranslation();
  const [ dataa, setDataa] = useState([]);
  const [open, setIsOpen] = useState(false);


  const data = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      'service_71cgivw',
      'template_q87s9fl',
      data.current,
      '7-NbVRdacC445n3OW'
    );

    e.target.reset();
  };


  useEffect(() => {
    fetch("https://backend.dasta.net/api/auth/getchangeemailphoto")
   .then((res)=>res.json())
   .then((data)=>{
     console.log(data);
     setDataa(data);
     });
 }, []);


  return (
    <div>
      <div className="container home-contact-container">
        <form ref={data} onSubmit={sendEmail} action="">
          <label htmlFor="">
            {t('say')} <span>{t('hello')}</span> {t('tous')}
          </label>
          <input type="text" placeholder={t('name')} name="fullName" required />
          <div>
            <input type="text" name="phone" placeholder={t('phone')} required />
            <input
              type="email"
              name="email"
              placeholder={t('email')}
              required
            />
          </div>
          <textarea
            name="message"
            id=""
            cols="5"
            rows="7"
            placeholder={t('message')}
            required
          ></textarea>
          <button className="send" type="submit">
            {t('send')}
          </button>
        </form>
        <div className="home-contact-image">
          {/* <img src={image} alt="" /> */}
          {
            dataa.map((data)=>{
              return(
                <>
                <img src={"https://backend.dasta.net/brand/"+ data.name } />
                </>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default HomeContact;
