import React from 'react';
import { RiAdminFill } from 'react-icons/ri';
import { TbBrandPython } from 'react-icons/tb';
import { FaAdversal } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';



import { Link, useNavigate } from 'react-router-dom';
import './homeStr.css';
const HomeStr = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation(); 
 useEffect(()=>{
  window.scrollTo(0, 0)
 }, [pathname])



  const strategyData = [
    {
      icon: RiAdminFill,
      link: t('ser1'),
      par: t('ser1des'),
      id: 1,
    },
    {
      icon: TbBrandPython,
      link: t('ser2'),
      par: t('ser2des'),
      id: 7,
    },

    {
      icon: FaAdversal,
      link: t('ser3'),
      par: t('ser3des'),
      id: 6,
    },
  ];
  return (
    <div className="str">
      <h2
        className="general-header"
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/services')}
      >
        {t('services')}
      </h2>
      <hr className="general-hr" />

      <div className="services" id="services">
        <div className="container services-container">
          {strategyData.map((strategy, index) => {
            return (
              <div className="service" key={index}>
                <div>
                  <strategy.icon
                    style={{
                      fontSize: '50px',
                      marginTop: '70px',
                      color: '#52adb8',
                    }}
                  />
                  <Link className="link-service" to="/services">
                    {strategy.link}
                  </Link>
                </div>
                <p>{strategy.par}</p>
                <button onClick={() => navigate('/services')} className="more">
                  {t('more')}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <button onClick={() => navigate('/services')} className="about-button Aaboat" >
                {t('allSevices')}  
      </button>
    </div>
  );
};

export default HomeStr;
