import { useParams } from "react-router-dom";
import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Editsonsection = () =>{
    const [photo, setPhoto] = useState('');
    const [name, setName] = useState('');
    let navigt = useNavigate();
    let { id } = useParams();
    const handleImage =(e)=>{
        setName(e.target.files[0]) 
    }
    const  formSubmit = (e)=>{
        e.preventDefault()
        const formData = new FormData()
        formData.append('name', name)
 
        axios.post(`https://backend.dasta.net/api/auth/updatesonsectionwokautomatic/${id}`,formData ).then((res)=>{
            console.log(res)
        }).then((data)=>{
            navigt(-1);
        })
    }  

    return(
        <>
        <form onSubmit={formSubmit}>
                <label>Please Enter Name::</label>
                <input type="text" id="fname" name="fname" onChange={(e)=>setName(e.target.value)}/>
            <button type='submit' >submit</button>
        </form>
        </>
    )

}
export default Editsonsection;