import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const Deletemebmerteam = () => {
    const [name, setName] = useState('');
    let { id } = useParams();
 //   const [id, setId] = useState(0);
    let navigt = useNavigate();


    const  deletemebmer = (e)=>{
            axios.delete(`https://backend.dasta.net/api/auth/deletenewteam/${id}`,{
                name,
            }).then((data)=>{
                navigt(-1);
            })
        }  
    return(
        
        <>
             {()=>deletemebmer()}
        </>
        
    )
}
export default Deletemebmerteam;