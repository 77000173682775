import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import { AiFillFacebook } from 'react-icons/ai';
import { AiOutlineYoutube } from 'react-icons/ai';
import { AiOutlineGoogle } from 'react-icons/ai';

import { BsInstagram } from 'react-icons/bs';

import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="footer-all">
      <div className="footer">
        <h1>info@dasta.net</h1>
        <div className="social-links">
          <a
            href="https://www.facebook.com/DastaProduction/"
            target="blank"
            className="link"
          >
            <AiFillFacebook></AiFillFacebook>
            {/* {t('facebook')} */}
          </a>
          <a
            href="https://www.instagram.com/dasta.agency/?igshid=YmMyMTA2M2Y%3D"
            target="blank"
            className="link"
          >
            <BsInstagram></BsInstagram>
            {/* {t('insta')} */}
          </a>

          <a
            href="https://www.instagram.com/dasta.agency/?igshid=YmMyMTA2M2Y%3D"
            target="blank"
            className="link"
          >
            <AiOutlineYoutube></AiOutlineYoutube>
          </a>
          <a
            href="https://www.instagram.com/dasta.agency/?igshid=YmMyMTA2M2Y%3D"
            target="blank"
            className="link"
          >
            <AiOutlineGoogle></AiOutlineGoogle>
          </a>
        </div>
      </div>

      <div className="copyright"> {t('bydasta')}</div>
    </div>
  );
};

export default Footer;
