import { useEffect } from 'react';
import { useState } from 'react';
import './Addcatetegery.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Adddiscoundd1 = () =>{
    const [details, setDetails] = useState('');
    const [price, setPrice] = useState(0);
    const [discound_id, setDiscound_id] = useState(0);

   // const [id, setId] = useState(0);

    const [details_id, setDetails_id] = useState(0);

    let { bandId } = useParams();
    let navigt = useNavigate();


    const  formSubmit = (e)=>{
            e.preventDefault()
            axios.post("https://backend.dasta.net/api/auth/adddescound",{
                price,
                details,
                discound_id : bandId,

            }).then((data)=>{
                navigt(-1);
            })

            
        }  
    return (
        <>
        
        <form onSubmit={formSubmit}>
            <label for="lname">Price:</label>
            <input type="text" id="lname" name="lname"  onChange={(e)=>setPrice(e.target.value)}/>
            <label for="lname">Details:</label>
            <input type="text" id="lname" name="lname"  onChange={(e)=>setDetails(e.target.value)}/>
            
            {/* <label for="lname">PLEASE ENTER THIS NUMBER {bandId}</label>
            <input type="text" id="lname" name="lname"  onChange={(e)=>setDiscound_id(e.target.value)}/>
             */}
            <button type='submit' >add</button>


            {/**
                <Link
                    className="link"
                    to = {`control/${categers.name}/${categers.id}`}
                    onClick={() => {
                    setIsOpen(false);
                    }}
                >

                </Link> */}
         </form> 
    </>
    )
}  
export default Adddiscoundd1;