import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Getsonsectionlink from '../sonsectionlink/Getsonsectionlink';
import Getsonsectionvideo from '../sonsectionvideo/Getsonsectionvideo';
import { useTranslation } from 'react-i18next';


const Getsonsectionphoto = ()=>{

    const [ data, setData] = useState([]);
    const [open, setIsOpen] = useState(false);
    let { id } = useParams();
    const [ data1, setData1] = useState([]);
    const { t, i18n } = useTranslation();




    useEffect(() => {
         fetch(`https://backend.dasta.net/api/auth/getsonbelongtosectionphoto/${id}`)
        .then((res)=>res.json())
        .then((data)=>{
          console.log(data);
          setData(data);
          });
          sonsection();
      }, []);

      const delet = (idd) => {         
        fetch(`https://backend.dasta.net/api/auth/deleteworksectionsonControllerphoto/${idd}`,{
            method: "DELETE"
        })
        .then((res) => res.json())
       }
       
      const sonsection = () => {
        fetch(`https://backend.dasta.net/api/auth/filtersonsectionwokautomatic/${id}`)
       .then((res)=>res.json())
       .then((data)=>{
         console.log(data);
         setData1(data);
         });
       }

    return(
        <>
        <br/>
        <br/> 
        {
          data1.map((data1)=>{
            return(
              <>       
             <h1 style={{color:"white", width:'10%', margin:'0px auto'}} >{data1.name}</h1>
              </>
            )
          })
        }
        <br/>
        <br/>
         <Link
        className="link"
        to = {`/addphotosonsectionwok/${id}`}
        onClick={() => {
        setIsOpen(false);
        }}
        >
        <Button className='buttonn' variant="success">Add New Image</Button>
        </Link>

        <br/>
        <br/>
        <br/>
        <div className=" galleries-container">
          
          {
            data.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.photo  }

                  alt=""
                />
                <div>
                    <h4 style={{
                                  textAlign: 'center',
                                  color: 'whitesmoke',
                                  width:'90%',
                                  wordWrap:'break-word',
                                  fontFamily:'cursive',
                                }}>
                        {data.name}
                    </h4>
                </div>

                
                <Link
                className="link"
                to = {`/changephotosonsection/${data.id}`}
                onClick={() => {
                setIsOpen(false);
                }}
                >
                <Button className='button' variant="success">Edit photo </Button>
                </Link>

                
                <Link
                className="link"
                to = {`/changenamesonsection/${data.id}`}
                onClick={() => {
                setIsOpen(false);
                }}
                >
                <Button className='button' variant="success">Edit name </Button>
                </Link>
                <Button className='button ' variant='success' onClick={ () => delet(data.id)} >delete</Button>
              </div>  
              )
            })
          } 
        </div>
        <Getsonsectionlink id = { id } />
        <Getsonsectionvideo id = { id } />

        
        </>
    )
}
export default Getsonsectionphoto;