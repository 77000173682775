import React from 'react';
import './collection.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import Details from '../details/Details';
import Sum from '../details/Sum';
import logo from '../../../assets/8dab4f88a129879185f50e7ed22aa8a2whats-removebg-preview (1).png';
import i18next from 'i18next';





const Collection = (props) => {

  function link() {
    //  👇️// redirect to external URL
     
      window.location.replace('https://wa.link/fiolo6');
   
     return null;
   }

  const { t, i18n } = useTranslation();

  let { name } = useParams();
  let { id } =useParams();
  const [band, setBand]= useState([]);
  const [catidNameEn, setCatidNameEn]= useState();
  const [catidNameAr, setCatidNameAr]= useState();
  const [val, setVal]= useState(true);
  const [val1, setVal1]= useState(true);

  let sum = props.sum;
  let nam = props.nam; 

  let lang;
  let langcat;


  useEffect(()=>{
    const fetch = async() => {
        try{
            const { data } = await axios.get(`https://backend.dasta.net/api/auth/filterbandBelongCate/${id}`);
                console.log(data);
                setBand(data);
            
        }catch(err){
            console.log("error");
            console.error(err);
        }      
    }
    fetch();
    fetch2();
  }, [id])

  const fetch2 = async() => {
    try{
        const { data } = await axios.get(`https://backend.dasta.net/api/auth/fetchspecficcate/${id}`);
            setCatidNameAr(data.name_ar);
            setCatidNameEn(data.name);
           
        
    }catch(err){
        console.log("error");
        console.error(err);
    }      
}




  function a(a){
    setVal1(a)
    console.log(val1)
    console.log(Boolean(a!==0))
  }

{/************************ */}
if(i18n.language=="en"){
  lang=
  band.map( band=>{
      return(
          <>
          <h2
              className="general-header found-header"
              style={{ marginTop: '100px',color:'#52adb8' }}
            >
              {band.name}
              </h2>
              <hr />
              <Details band = { band } val={val} a={a}/>
          </>
      )
  })
}else{
  lang=
  band.map( band=>{
      return(
          <>
          <h2
              className="general-header found-header"
              style={{ marginTop: '100px',color:'#52adb8' }}
            >
              {band.name_ar}
              </h2>
              <hr />
              <Details band = { band } val={val} a={a}/>
          </>
      )
  })
}
 

if(i18n.language=="en"){
   langcat = 
   <>
   {catidNameEn}
   </>
    
}else{
  langcat =
  <> {catidNameAr}</>
}

  return (
    <div className="plans">
      <div className="general-background-image" >
        <div className="back">

         {/* <h2>{t('webAppPlan')}</h2>*/}
         {/*
         to show the name cateders dynamic 
         */}
         <h2> {langcat} </h2>
         <br/>
          <Link className="link back-link" to="/">
            {t('home')}
          </Link>
        </div>
      </div>

      <div className="webapp-plans container">
        
            {/*
                to show product dynaamic 
            */}
            {
              lang
            }
            
            <div className='total' hidden={val1!==0} >
                <br/>
                <br/>
                <br/>
                <Link to ={`/custmize/${id}/${name}`}  >
                    <button className='btn btn4 btn5'/*onClick={()=>{setVal(false)}}*/ >{t('cus')}</button>
                </Link>
                <br/>
{/*                 
                 <Link to ={`/custmize2/${id}`}  >
                    <button className='btn btn4 btn5' >Custmize2 Your Package</button>
                </Link>
                  */}
                <h5 style={{textAlign: 'center'}}>{t('cus2')}</h5>
                <br/>
                <br/>
            </div>  
             {/* Web & App package*/}
         
    

  
{/*
        <table>
          <tr>
            <th>Band</th>
            <th>Details</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Buy</th>
          </tr>
          <tr>
            <td>Web site</td>
            <td>word press + host + free domain renewal</td>
            <td>1</td>
            <td>5000</td>
            <td>
              <button className="btn">Buy</button>
            </td>
          </tr>
          <tr>
            <td>Web store</td>
            <td>Dynamic + host + free domain</td>
            <td>1</td>
            <td>15000</td>
            <td>
              <button className="btn">Buy</button>
            </td>
          </tr>
          <tr>
            <td>Mobile App</td>
            <td>Flutter Android + IOS</td>
            <td>1</td>
            <td>15000</td>
            <td>
              <button className="btn">Buy</button>
            </td>
          </tr>
          <tr>
            <td>APP Store</td>
            <td>Flutter Android + IOS</td>
            <td>1</td>
            <td>25000</td>
            <td>
              <button className="btn">Buy</button>
            </td>
          </tr>
          </table>*/}
      </div>
{/* {i18n.language} */}
      
      <img className='whatsappIcon' src={logo} alt='logo' 
            
             onClick={()=>link()}
             />  
    </div>

  );
};

export default Collection;
