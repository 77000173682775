import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateArabic from './local/ar.json';
import translateEnglish from './local/en.json';
import languageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: translateEnglish,
  },
  ar: {
    translation: translateArabic,
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
