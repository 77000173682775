import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Getsectionwok from './sectionwok/Getsectionwok';


const Mainwork  = () => {
     const [open, setIsOpen] = useState(false);

    // const [data1, setData1] = useState([]);
    // const [data2, setData2] = useState([]);
    // const [data3, setData3] = useState([]);
    // const [data4, setData4] = useState([]);
    // const [data5, setData5] = useState([]);
    // const [data6, setData6] = useState([]);
    // const [data7, setData7] = useState([]);
    // const [data8, setData8] = useState([]);
    // const [data9, setData9] = useState([]);


    // const [data, setData] = useState([]);

  
    // useEffect(() => {
    //   brand();
    //   decor();
    //   door();
    //   gift();
    //   media();
    //   offset();
    //   photo();
    //   social();
    //   web();

        
    //  }, []);

    //  const brand = () => {
    //   fetch("https://backend.dasta.net/api/auth/getphotoourworkbandsection")
    //  .then((res)=>res.json())
    //  .then((data)=>{
    //    console.log(data);
    //    setData1(data);
    //    });
    //  }

    //  const decor = () => {
    //   fetch("https://backend.dasta.net/api/auth/getphotoourworkdecorsection")
    //  .then((res)=>res.json())
    //  .then((data)=>{
    //    console.log(data);
    //    setData2(data);
    //    });
    //  }

    //  const door = () => {
    //   fetch("https://backend.dasta.net/api/auth/getphotoourworkdoorsection")
    //  .then((res)=>res.json())
    //  .then((data)=>{
    //    console.log(data);
    //    setData3(data);
    //    });
    //  }

    //  const gift = () => {
    //   fetch("https://backend.dasta.net/api/auth/getphotoourworkgiftsection")
    //  .then((res)=>res.json())
    //  .then((data)=>{
    //    console.log(data);
    //    setData4(data);
    //    });
    //  }

    //  const media = () => {
    //   fetch("https://backend.dasta.net/api/auth/getphotoourworkmediasection")
    //  .then((res)=>res.json())
    //  .then((data)=>{
    //    console.log(data);
    //    setData5(data);
    //    });
    //  }

    //  const offset = () => {
    //   fetch("https://backend.dasta.net/api/auth/getphotoourworkoffsetsection")
    //  .then((res)=>res.json())
    //  .then((data)=>{
    //    console.log(data);
    //    setData6(data);
    //    });
    //  }

    //  const photo = () => {
    //   fetch("https://backend.dasta.net/api/auth/getphotoourworkphotographysection")
    //  .then((res)=>res.json())
    //  .then((data)=>{
    //    console.log(data);
    //    setData7(data);
    //    });
    //  }

    //  const social = () => {
    //   fetch("https://backend.dasta.net/api/auth/getphotoourworksocialsection")
    //  .then((res)=>res.json())
    //  .then((data)=>{
    //    console.log(data);
    //    setData8(data);
    //    });
    //  }

    //  const web = () => {
    //   fetch("https://backend.dasta.net/api/auth/getphotoourworkwebsection")
    //  .then((res)=>res.json())
    //  .then((data)=>{
    //    console.log(data);
    //    setData9(data);
    //    });
    //  }



    //  const deletBrand = (id) => {         
    //     fetch(`https://backend.dasta.net/api/auth/deletephotoourworkbandsection/${id}`,{
    //         method: "DELETE"
    //     })
    //     .then((res) => res.json())
    //    }


    //    const deletDecor = (id) => {         
    //     fetch(`https://backend.dasta.net/api/auth/deletephotoourworkdecorsection/${id}`,{
    //         method: "DELETE"
    //     })
    //     .then((res) => res.json())
    //    }


    //    const deletDoor = (id) => {         
    //     fetch(`https://backend.dasta.net/api/auth/deletephotoourworkdoorsection/${id}`,{
    //         method: "DELETE"
    //     })
    //     .then((res) => res.json())
    //    }


    //    const deletGift = (id) => {         
    //     fetch(`https://backend.dasta.net/api/auth/deletephotoourworkgiftsection/${id}`,{
    //         method: "DELETE"
    //     })
    //     .then((res) => res.json())
    //    }


    //    const deletMedia = (id) => {         
    //     fetch(`https://backend.dasta.net/api/auth/deletephotoourworkmediasection/${id}`,{
    //         method: "DELETE"
    //     })
    //     .then((res) => res.json())
    //    }


    //    const deletOffest = (id) => {         
    //     fetch(`https://backend.dasta.net/api/auth/deletephotoourworkoffsetsection/${id}`,{
    //         method: "DELETE"
    //     })
    //     .then((res) => res.json())
    //    }


    //    const deletPhoto = (id) => {         
    //     fetch(`https://backend.dasta.net/api/auth/deletephotoourworkphotographysection/${id}`,{
    //         method: "DELETE"
    //     })
    //     .then((res) => res.json())
    //    }


    //    const deletSocial = (id) => {         
    //     fetch(`https://backend.dasta.net/api/auth/deletephotoourworksocialsection/${id}`,{
    //         method: "DELETE"
    //     })
    //     .then((res) => res.json())
    //    }


    //    const deletWeb = (id) => {         
    //     fetch(`https://backend.dasta.net/api/auth/deletephotoourworkwebsection/${id}`,{
    //         method: "DELETE"
    //     })
    //     .then((res) => res.json())
    //    }




    return( 
<>
      <Link
      className="link"
      to = '/addsectionwok'
      onClick={() => {
      setIsOpen(false);
      }}
  >
  <Button className='buttonn' variant="success">Add New Section</Button>
  </Link>
    <div className="services" id="services">
      <div className="container services-container">
{/* 

      <div className="service">
          <br/>
          <br/>
         <h2>Branding</h2>
          <Link
          className="link"
          to = '/ControlBrand'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > View </button>
          </Link>

          
          <Link
          className="link"
          to = '/addphotosectionbrand'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > Add photo </button>
          </Link>


          {
            data1.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
                <Button className='button ' variant='success' onClick={ () => deletBrand(data.id)} >delete</Button>
              </div>  
              )
            })
          } 



        </div>

        
        <div className="service">
          <br/>
          <br/>
         <h2>Decor</h2>
          <Link
          className="link"
          to = '/ControlDecor'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > View </button>
          </Link>

          
          
          <Link
          className="link"
          to = '/addphotosectiondecor'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > Add photo </button>
          </Link>


          {
            data2.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
                <Button className='button ' variant='success' onClick={ () => deletDecor(data.id)} >delete</Button>
              </div>  
              )
            })
          } 

        </div>




        <div className="service">
          <br/>
          <br/>
         <h2>Web&App</h2>
          <Link
          className="link"
          to = '/Controlweb'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > View </button>
          </Link>
          <Link
          className="link"
          to = '/addphotosectionweb'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > Add photo </button>
          </Link>


          {
            data9.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
                <Button className='button ' variant='success' onClick={ () => deletWeb(data.id)} >delete</Button>
              </div>  
              )
            })
          } 

        </div>


        <div className="service">
          <br/>
          <br/>
         <h2>Social media</h2>
          <Link
          className="link"
          to = '/ControlSocial'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > View </button>
          </Link>
          <Link
          className="link"
          to = '/addphotosectionsocial'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > Add photo </button>
          </Link>
          {
            data8.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
                <Button className='button ' variant='success' onClick={ () => deletSocial(data.id)} >delete</Button>
              </div>  
              )
            })
          } 

        </div>

         
        <div className="service">
          <br/>
          <br/>
         <h2>Media & Design</h2>
          <Link
          className="link"
          to = '/Controlmedia'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > View </button>
          </Link>
          <Link
          className="link"
          to = '/addphotosectionmedia'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > Add photo </button>
          </Link>


          {
            data5.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
                <Button className='button ' variant='success' onClick={ () => deletMedia(data.id)} >delete</Button>
              </div>  
              )
            })
          } 

        </div>



        <div className="service">
          <br/>
          <br/>
         <h2>photography</h2>
          <Link
          className="link"
          to = '/ControlPhotography'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > View </button>
          </Link>
          <Link
          className="link"
          to = '/addphotosectionphoto'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > Add photo </button>
          </Link>

          {
            data7.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
                <Button className='button ' variant='success' onClick={ () => deletPhoto(data.id)} >delete</Button>
              </div>  
              )
            })
          } 

        </div>

        
        <div className="service">
          <br/>
          <br/>
         <h2>Offset Prints</h2>
          <Link
          className="link"
          to = '/Controloffset'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > View </button>
          </Link>
          
          
          <Link
          className="link"
          to = '/addphotosectionoffset'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > Add photo </button>
          </Link>


          {
            data6.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
                <Button className='button ' variant='success' onClick={ () => deletOffest(data.id)} >delete</Button>
              </div>  
              )
            })
          } 

        </div>


        



       
        <div className="service">
          <br/>
          <br/>
         <h2>Gift Items </h2>
          <Link
          className="link"
          to = '/Controlgift'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > View </button>
          </Link>

          
          
          <Link
          className="link"
          to = '/addphotosectiongift'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > Add photo </button>
          </Link>


          {
            data4.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
                <Button className='button ' variant='success' onClick={ () => deletGift(data.id)} >delete</Button>
              </div>  
              )
            })
          } 

        </div>

        <div className="service">
          <br/>
          <br/>
         <h2>INDOOR&OUTDOOR</h2>
          <Link
          className="link"
          to = '/Controldoor'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > View </button>
          </Link>

          
          
          <Link
          className="link"
          to = '/addphotosectiondoor'
          onClick={() => {
            setIsOpen(false);
          }}
          >
            <button className ='btn' > Add photo </button>
          </Link>


          {
            data3.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
                <Button className='button ' variant='success' onClick={ () => deletDoor(data.id)} >delete</Button>
              </div>  
              )
            })
          } 

        </div> */}

        {/************************************************************************* */}



<Getsectionwok/>






      </div>
    </div>
    </>
    )

}
export default Mainwork;