import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Getlinkgift from '../link/Getlinkgift';
import Getvideogift from '../video/Getvideogift';

const Getphotogift = ()=>{

    // const [ data, setData] = useState([]);
    // const [open, setIsOpen] = useState(false);


    // useEffect(() => {
    //      fetch("https://backend.dasta.net/api/auth/getphotoourworkgift")
    //     .then((res)=>res.json())
    //     .then((data)=>{
    //       console.log(data);
    //       setData(data);
    //       });
    //   }, []);

    //   const delet = (id) => {         
    //     fetch(`https://backend.dasta.net/api/auth/deletephotoourworkgift/${id}`,{
    //         method: "DELETE"
    //     })
    //     .then((res) => res.json())
    //    }

    // return(
    //     <>
    //     <br/>
    //     <br/>
    //     <br/>
    //     <h1 style={{color:"white", width:'20%', margin:'0px auto'}} >Gift Items</h1>
        
    //     <br/>
    //     <br/>
    //     <br/>
    //     <br/>
    //      <Link
    //     className="link"
    //     to = '/addphototogift'
    //     onClick={() => {
    //     setIsOpen(false);
    //     }}
    //     >
    //     <Button className='buttonn' variant="success">Add New Image</Button>
    //     </Link>
    //     <br/>
    //     <br/>
    //     <br/>
    //     <div className=" galleries-container">
          
    //       {
    //         data.map((data)=>{
    //           return(
    //             <div className="gallery" >
    //             <img
    //             src={"https://backend.dasta.net/brand/"+ data.name  }

    //               alt=""
    //             />
                
    //             <Link
    //             className="link"
    //             to = {`/changephotogift/${data.id}`}
    //             onClick={() => {
    //             setIsOpen(false);
    //             }}
    //             >
    //             <Button className='button' variant="success">Change This Photo </Button>
    //             </Link>
    //             <Button className='button ' variant='success' onClick={ () => delet(data.id)} >delete</Button>
    //           </div>  
    //           )
    //         })
    //       } 
    //     </div>
    //     {
    //       <Getlinkgift/>
    //     }
    //     {
    //       <Getvideogift/>
    //     }

        
    //     </>
    // )
}
export default Getphotogift;