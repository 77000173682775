
import React, { useEffect } from 'react';
import { useState } from 'react';
import "./getphoto.css"
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import {Container , Button, Col} from 'react-bootstrap';




const Getteamwork = () =>{
    let a = 0 ;
    const [open, setIsOpen] = useState(false);
    const [image, setImage] = useState([]);
    {/* me*/} 
    const [ photo, setPhoto] =  useState([]);
    const [ name, setName] =  useState([]);
  
    useEffect(() => {
      {/**me */}  fetch(`https://backend.dasta.net/api/auth/filterdetailBelongToTeam/${image}`)
      .then((res)=>res.json())
      .then((data)=>{
        console.log(data);
        setPhoto(data);
        });
        fetch("https://backend.dasta.net/api/auth/getnewteam")
      .then((res)=>res.json())
      .then((data)=>{
        console.log(data);
        setName(data);
        });
    }, [image]);

    // const deleteBand = (bandId) => {
    //   fetch(`https://backend.dasta.net/api/auth/deletaband/${image}`,{
    //     method: "DELETE"
    // })
    // .then((res) => res.json())      
  
    //  }
 
    const deletea = (bandId) => {
      fetch(`https://backend.dasta.net/api/auth/deltenewteam/${bandId}`,{
          method: "DELETE"
      })       
 }
  
 const deletes = (bandId) => {
  fetch(`https://backend.dasta.net/api/auth/deletephotonewteam/${bandId}`,{
      method: "DELETE"
  })       
}
    return (
      <div className="team-work ">
        <div className="container team-work-container">
          <div className="team-work-persons">

          <Link
            className="link"
            to = {`/addnewteamwork`}
            onClick={() => {
            setIsOpen(false);
            }}
        >
          <Button className='btn' variant="success">Add New Member</Button>
        </Link>
          
  
            {
              name.map((name)=>{
                return(
                  <>
                  <Link
                    onClick={() => setImage(name.id)}
                    className="link team-link team-work-one"
                  >
                   <span>{name.name}</span>
                   <br/>
                    <Link
                    className="link"
                    to =  {`/editnameofmember/${name.id}`}
                    onClick={() => {
                    setIsOpen(false);
                    }}
                  >
                    <button className='btn2'>Edit</button>
                    </Link>
                    {/* <Link
                    className="link"
                    
                    onClick={() => { deleteBand() }}
                  >
                    <button className='btn1'>Delete</button>
                    </Link> */}
                   <br/>                    
                    <Link
                    className="link"
                    to =  {`/addphotoformember/${name.id}`}
                    onClick={() => {
                    setIsOpen(false);
                    }}
                  >
                   <button className='btn2 btn5'>Add photo</button>
                    </Link>
                    </Link>
                   <button className='btn1' onClick={()=>deletea(name.id)}>Delete</button>


                  </>
                )
              })
            }
         
          </div>
          <div className="team-work-images">
            {
             photo.map((photo=>{
              return(
                <>
                   <Link
                    className="link"
                    to =  {`/changephotoformember/${photo.id}`}
                    onClick={() => {
                    setIsOpen(false);
                    }}
                  >
                   <button className='btn5 btn2'>Change photo</button>
                    </Link>


               <img src ={"https://backend.dasta.net/teamPhotos/" + photo.name}   max-width={250} height={350} />
                </>
              )
            }))
            }
          </div>
        </div>
      </div>
    );
  
} 
export default Getteamwork;