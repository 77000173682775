import { useEffect } from 'react';
import { useState } from 'react';
import './Addcatetegery.css';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Editproduct = () => {
    const [name, setName] = useState('');
  //  const [idd, setIdd] = useState(0);
    let navigt = useNavigate();
    let { id } =useParams();



    const  formSubmit = (e)=>{
            e.preventDefault()
            axios.post(`https://backend.dasta.net/api/auth/updateband/${id}`,{
                name,
            }).then((data)=>{
                navigt(-1);
            })
        }  
    return(

        <>
        <form onSubmit={formSubmit}>
            <label for="fname" >Name Of Band:</label>
            <input type="text" id="fname" name="fname" onChange={(e)=>setName(e.target.value)}/>
            <button type='submit' >Update</button>
        </form> 
        </>
    )

}

export default Editproduct;