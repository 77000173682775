import { useEffect } from 'react';
import { useState } from 'react';
import './Addcatetegery.css';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Editdiscound = ()=>{
    const [details, setDetails] = useState('');
    const [price, setPrice] = useState(0);
 //   const [wdetails_id, setWdetails_id] = useState(0);
    let navigt = useNavigate();
    let  { bandId }  =useParams();



    const  formSubmit = (e)=>{
            e.preventDefault()
            axios.post(`https://backend.dasta.net/api/auth/updatedescound/${bandId}`,{
                details,
                price,
               // wdetails_id,

            }).then((data)=>{
                navigt(-1);
            })
        }  

    return (
        
        <>
        <form onSubmit={formSubmit}>
            <label for="lname">Price:</label>
            <input type="text" id="lname" name="lname"  onChange={(e)=>setPrice(e.target.value)}/>
            <label for="lname">Details:</label>
            {/* <input type="text" id="lname" name="lname"  onChange={(e)=>setDetails(e.target.value)}/> */}
            <textarea  rows={4} cols={40} onChange={(e)=>setDetails(e.target.value)}/>            
            
            <button type='submit' >Update</button>
        </form> 


        </>

    )
}
export default Editdiscound;