import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import './login.css';
import { useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
//import Header from './Header';
const Login =()=>{
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [data, setData] = useState([]);
    const [status, setStatus] = useState([]);
    const [id, setId] = useState(0);
    let navigt = useNavigate();
   // const histort = useHistory();
    // useEffect(()=>{ 
    //     fetch('https://backend.dasta.net/api/auth/login',{
    //                 method: "GET"
    //             })
    //             .then((res) => res.json())
    //             setData(data)
    // },[])


    const  formSubmit = (e)=>{
            e.preventDefault()
            axios.post("https://backend.dasta.net/api/auth/login",{
               email,
               password,

            }).then((data)=>{
                console.log(data);
               // setData(data);
                if( Object.keys(data.data).length == 1 ){
                navigt('/Control');
                }else{
                alert('Email Or Password Incorrect');
                navigt('/login');
                
                }
            })
        }  
    return(
        <div className='Alogin'>
            <form onSubmit={formSubmit}>
                <label for="fname" >Enter Email:</label>
                <input type="text" id="fname" name="fname" onChange={(e)=>setEmail(e.target.value)}/>
             
                <label for="fname" >Enter Password</label>
                <input type="password" id="fname" name="fname" onChange={(e)=>setPassword(e.target.value)}/>
                <button type='submit'  >Submit</button>
           
             </form>
              {/* {
                data.map((data)=>{
                    return(
                        <>
                            {setId(data.id)}
                        </>
                    )
                })
             }   */}
        </div>
        
    )
}
export default Login;