import About from './components/about/About';
import Blog from './components/bolg/Blog';
import Contact from './components/contact/Contact';
import Events from './components/events/Events';
import Gallery from './components/gallery/Gallery';
import Home from './components/home/Home';
import Navbar from './components/navbar/Navbar';
import Services from './components/services/Services';
import { Routes, Route } from 'react-router-dom';
import Service from './components/services/service/Service';
import { useState } from 'react';
import Branding from './components/products/branding1/Branding';
import SocialMedia from './components/products/socialMedia/SocialMedia';
import WebApp from './components/products/web&app/WebApp';
import MediaGraphic from './components/products/mediaGraphic/MediaGraphic';
import PhotoVideos from './components/products/photoAndVideos/PhotoVideos';
import OffsitPrints from './components/products/offsetPrints/OffsitPrints';
import OutDoorIndoor from './components/products/outdoorAndIndoor/OutDoorIndoor';
import Footer from './components/footer/Footer';
import Collection from './components/products/collection/Collection';
import Details from './components/products/details/Details';
import Control from './components/dashbord/Control';
import ShowNavbar from './components/ShowNavbar';
import Controlproduct from './components/dashbord/content/Controlproduct';
import Controlcategery from './components/dashbord/content/Controlcategery';
import Controldetails from './components/dashbord/content/Controldetails';
import Addcatetegery from './components/dashbord/content/Addcatetegery';
import Editcatetegery from './components/dashbord/content/Editcatetegery';
import Editproduct from './components/dashbord/content/Editproduct';
import Addproduct from './components/dashbord/content/Addproduct';
import Generaldetails from './components/dashbord/content/Generaldetails';
import Adddetailsdis from './components/dashbord/content/Adddetailsdis';
import Adddetailwithout from './components/dashbord/content/Adddetailwithout';
import Editdetailswithoutdis from './components/dashbord/content/Editdetailswithoutdis';
import Editdetailsdis from './components/dashbord/content/Editdetailsdis';
import Sum from './components/products/details/Sum';
import Editdiscound from './components/dashbord/content/Editdiscound';
import Adddiscoundd1 from './components/dashbord/content/Adddiscoundd1';
import Controlmainhome from './components/dashbord/main/Controlmainhome';
import Getphotowork from './components/dashbord/main/Getphotowork';
import Addphoto from './components/dashbord/main/Addphoto';
import Changephoto from './components/dashbord/main/Changephoto';
import Getteamwork from './components/dashbord/main/Getteamwork';
import Editmemberteam from './components/dashbord/main/Editmemberteam';
import Deletemebmerteam from './components/dashbord/main/Deletemebmerteam';
import Addnewmemberteam from './components/dashbord/main/Addnewmemberteam';
import Addphototeamwork from './components/dashbord/main/Addphototeamwork';
import Edithototeamwork from './components/dashbord/main/Edithototeamwork';
import Mainwork from './components/workControl/Mainwork';
import Controlweb from './components/workControl/web&app/Controlweb';
import Addphotoapp from './components/workControl/web&app/Addphotoapp';
import Editphotoapp from './components/workControl/web&app/Editphotoapp';
import Addvideoapp from './components/workControl/web&app/video/Addvideoapp';
import Editvideo from './components/workControl/web&app/video/Editvideo';
import Adddlinkapp from './components/workControl/web&app/link/Adddlinkapp';
import Getphotodecor from './components/workControl/decor/photo/Getphotodecor';
import Editphotodecor from './components/workControl/decor/photo/Editphotodecor';
import Addphotodecor from './components/workControl/decor/photo/Addphotodecor';
import Addvideodecor from './components/workControl/decor/video/Addvideodecor';
import Editvideodecor from './components/workControl/decor/video/Editvideodecor';
import Addlinkdecor from './components/workControl/decor/link/Addlinkdecor';
import Addphotobrand from './components/workControl/brand/photo/Addphotobrand';
import Editphotobrand from './components/workControl/brand/photo/Editphotobrand';
import Addlinkbrand from './components/workControl/brand/link/Addlinkbrand';
import Addvideobrand from './components/workControl/brand/video/Addvideobrand';
import Getvideobrand from './components/workControl/brand/video/Getvideobrand';
import Addphotosocial from './components/workControl/social/photo/Addphotosocial';
import Editphotosocial from './components/workControl/social/photo/Editphotosocial';
import Addlinksocial from './components/workControl/social/link/Addlinksocial';
import Addvideosocail from './components/workControl/social/video/Addvideosocail';
import Editvideosocial from './components/workControl/social/video/Editvideosocial';
import Getphotosocial from './components/workControl/social/photo/Getphotosocial';
import Getphotobrand from './components/workControl/brand/photo/Getphotobrand';
import Addphotophotograpgy from './components/workControl/photography/photo/Addphotophotograpgy';
import Editphotophotograpgy from './components/workControl/photography/photo/Editphotophotograpgy';
import Adddlinkphotograpgy from './components/workControl/photography/link/Adddlinkphotograpgy';
import Addvideophotograpgy from './components/workControl/photography/video/Addvideophotograpgy';
import Edidvideophotography from './components/workControl/photography/video/Edidvideophotography';
import Getphotophotograpgy from './components/workControl/photography/photo/Getphotophotograpgy';
import Addphotomedia from './components/workControl/media/photo/Addphotomedia';
import Editphotomedia from './components/workControl/media/photo/Editphotomedia';
import Addvideomedia from './components/workControl/media/video/Addvideomedia';
import Editivedeomedia from './components/workControl/media/video/Editivedeomedia';
import Addlinkmedi from './components/workControl/media/link/Addlinkmedi';
import Getphotomedia from './components/workControl/media/photo/Getphotomedia';
import Addphotodoor from './components/workControl/door/photo/Addphotodoor';
import Editphotodoor from './components/workControl/door/photo/Editphotodoor';
import Getphotodoor from './components/workControl/door/photo/Getphotodoor';
import Addlinkdoor from './components/workControl/door/link/Addlinkdoor';
import Addvideodoor from './components/workControl/door/video/Addvideodoor';
import Getphotogift from './components/workControl/gift/photo/Getphotogift';
import Addphotogift from './components/workControl/gift/photo/Addphotogift';
import Editphotogift from './components/workControl/gift/photo/Editphotogift';
import Addlinkgift from './components/workControl/gift/link/Addlinkgift';
import Addvideogift from './components/workControl/gift/video/Addvideogift';
import Addphotooffest from './components/workControl/offest/photo/Addphotooffest';
import Editphotooffest from './components/workControl/offest/photo/Editphotooffest';
import Addlinkoffest from './components/workControl/offest/link/Addlinkoffest';
import Addvideooffest from './components/workControl/offest/video/Addvideooffest';
import Getphotooffest from './components/workControl/offest/photo/Getphotooffest';
import Getphotobrand1 from './components/gallery/brand/Getphotobrand1';
import Getphotodecor1 from './components/gallery/decor/Getphotodecor1';
import Getphotoweb1 from './components/gallery/web/Getphotoweb1';
import Getphotdoor1 from './components/gallery/door/Getphotdoor1';
import Getphotogift1 from './components/gallery/gift/Getphotogift1';
import Getphotomedia1 from './components/gallery/media/Getphotomedia1';
import Getoffsetphoto1 from './components/gallery/offset/Getoffsetphoto1';
import Getphotosocial1 from './components/gallery/social/Getphotosocial1';
import Getpphotograph1 from './components/gallery/photography/Getpphotograph1';
import Login from './components/dashbord/Login';
import Addphotoaboat from './components/dashbord/Changephoto/changeabout/Addphotoaboat';
import Editphotoabout from './components/dashbord/Changephoto/changeabout/Editphotoabout';
import Editphotoemail from './components/dashbord/Changephoto/chnageemail/Editphotoemail';
import Addphotoemail from './components/dashbord/Changephoto/chnageemail/Addphotoemail';
import Getphotoabout from './components/dashbord/Changephoto/changeabout/Getphotoabout';
import Getphotoemail from './components/dashbord/Changephoto/chnageemail/Getphotoemail';
import Getpphotogift1 from './components/gallery/gift/Getpphotogift1';
import Addphotosectionbrand from './components/workControl/section/branding/Addphotosectionbrand';
import Addphotosectiondecor from './components/workControl/section/decor/Addphotosectiondecor';
import Addphotosectionweb from './components/workControl/section/web/Addphotosectionweb';
import Addphotosectionsocial from './components/workControl/section/social/Addphotosectionsocial';
import Addphotosectionmedia from './components/workControl/section/media/Addphotosectionmedia';
import Addphotosectionphotography from './components/workControl/section/photography/Addphotosectionphotography';
import Addphotosectionoffset from './components/workControl/section/offset/Addphotosectionoffset';
import Addphotosectionggift from './components/workControl/section/gift/Addphotosectionggift';
import Addphotosectiondoor from './components/workControl/section/door/Addphotosectiondoor';
import Edittotal from './components/dashbord/content/Edittotal';
import Addtotal from './components/dashbord/content/Addtotal';
import Custmize from './components/products/details/Custmize';
import Sendmessagewhats from './components/Sendmessagewhats';
import Editsectionwok from './components/workControl/sectionwok/Editsectionwok';
import Addsectionwok from './components/workControl/sectionwok/Addsectionwok';
import Getsonsection from './components/workControl/sonsection/Getsonsection';
import Addsonsection from './components/workControl/sonsection/Addsonsection';
import Editsonsection from './components/workControl/sonsection/Editsonsection';
import Getsonsectionphoto from './components/workControl/sonsectionphoto/Getsonsectionphoto';
import Addsonsectionphoto from './components/workControl/sonsectionphoto/Addsonsectionphoto';
import Editsonsectionphoto from './components/workControl/sonsectionphoto/Editsonsectionphoto';
import Addsonsectionlink from './components/workControl/sonsectionlink/Addsonsectionlink';
import Editsonsectionlink from './components/workControl/sonsectionlink/Editsonsectionlink';
import Addsonsectionvideo from './components/workControl/sonsectionvideo/Addsonsectionvideo';
import Editsonsectionvideo from './components/workControl/sonsectionvideo/Editsonsectionvideo';
import Getsonsection1 from './components/gallery/sonsection/Getsonsection1';
import Getsonsectionphoto1 from './components/gallery/sonsectionphoto/Getsonsectionphoto1';
import Editsonsectionphotophoto from './components/workControl/sonsection/Editsonsectionphotophoto';
import Editsectionphotophotoonl from './components/workControl/sectionwok/Editsectionphotophotoonl';
import Sendmassagewhatspackage from './components/Sendmassagewhatspackage';
import Custmize2 from './components/products/details/Custmize2';
import Editsonsectionname from './components/workControl/sonsectionphoto/Editsonsectionname';
import Thanks from './components/Thanks';

function App() {
  const [serviceId, setServiceId] = useState('');
  return (
    <div className="App">
      <ShowNavbar>
      <Navbar />
      </ShowNavbar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/services"
          element={<Services setServiceId={setServiceId} />}
        />
        <Route path="/services/:id" element={<Service service={serviceId} />} />
        <Route path="/work" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/brand" element={<Branding />} />
        <Route path="/social" element={<SocialMedia />} />
        <Route path="/webapp" element={<WebApp />} />
        <Route path="/media" element={<MediaGraphic />} />
        <Route path="/photovideos" element={<PhotoVideos />} />
        <Route path="/offset" element={<OffsitPrints />} />
        <Route path="/outindoor" element={<OutDoorIndoor />} />



          {/* show module in website  */}
        <Route path=":name/:id" element={<Collection />} />
        <Route path="/collection" element={<Collection />} />
        <Route path="/detail" element={<Details />} />
          {/** dashbourd */}
        <Route path="/control" element={<Control />} />
        <Route path="/Controlproduct" element={<Controlproduct />} />
        <Route path="/Controlctegery" element={<Controlcategery />} />
        <Route path="/controldetails" element={<Controldetails />} />
        <Route path="/addcategery" element={<Addcatetegery />} />
        <Route path="/addproduct/:cateId" element={<Addproduct />} />
        {/**to choose between poposal and package  */}
        <Route path="/adddetails/:bandId/:id" element={<Generaldetails />} />

        <Route path="/addproductwithdiscount/:bandId" element={<Adddetailsdis />} />
        <Route path="/addproductwithoutdiscount/:bandId/:id" element={<Adddetailwithout />} />
        <Route path="/addproductwithdiscount/:bandId" element={<Adddetailsdis />} />
        


        <Route path="/editdetailswithoutdis/:detailsId" element={<Editdetailswithoutdis />} />
        <Route path="/editdetailsdis/:detailsId" element={<Editdetailsdis />} />
        <Route path="/adddis/:bandId" element={<Adddiscoundd1 />} />
        <Route path="/editdis/:bandId" element={<Editdiscound />} />


        <Route path="/edittotal/:bandId" element={<Edittotal />} />
        <Route path="/addtotal/:bandId" element={<Addtotal />} />




        

        {/*<Route path="/addproductwithoutdiscount/:detailsId" element={<Editdetailsdis />} />*/}




        <Route path="Controlctegery/:name/:id" element={<Controlproduct />} />

        <Route path="/editcategery/:id" element={<Editcatetegery />} />
        <Route path="/editproduct/:id" element={<Editproduct />} />


        <Route path="/sum" element={<Sum />} />
        <Route path="/custmize/:id/:name" element={<Custmize/>} />
        <Route path="/custmize2/:id" element={<Custmize2/>} />


        {/** control on home to add &remove image for work&&teamworks */}
        <Route path="/Controlmainhome" element={<Controlmainhome />} />
        <Route path="/toveiwphoto" element={<Getphotowork />} />
        <Route path="/addphoto" element={<Addphoto />} />
        <Route path="/changephoto/:id" element={<Changephoto />} />
        <Route path="/toviewteamwork" element={<Getteamwork />} />
        <Route path="/editnameofmember/:id" element={<Editmemberteam />} />
        <Route path="/deletenameofmember/:id" element={<Deletemebmerteam />} />
        <Route path="/addnewteamwork" element={<Addnewmemberteam />} />
        <Route path="/addphotoformember/:id" element={<Addphototeamwork />} />
        <Route path="/changephotoformember/:id" element={<Edithototeamwork />} />

        {/** control on owur work */}
        <Route path="/Mainwork" element={<Mainwork />} />
        <Route path="/Controlweb" element={<Controlweb />} />
        <Route path="/ControlDecor" element={<Getphotodecor />} />
        <Route path="/ControlBrand" element={<Getphotobrand />} />
        <Route path="/ControlSocial" element={<Getphotosocial />} />
        <Route path="/ControlPhotography" element={<Getphotophotograpgy />} />
        <Route path="/Controlmedia" element={<Getphotomedia />} />
        <Route path="/Controldoor" element={<Getphotodoor />} />
        <Route path="/Controloffset" element={<Getphotooffest />} />
        <Route path="/Controlgift" element={<Getphotogift />} />
       
        {/**Show gallay  */}
        <Route path="/gobrand" element={<Getphotobrand1 />} />
        <Route path="/godecor" element={<Getphotodecor1 />} />
        <Route path="/goweb" element={<Getphotoweb1 />} />
        <Route path="/godoor" element={<Getphotdoor1 />} />
        <Route path="/gogift" element={<Getphotogift1 />} />
        <Route path="/gomedia" element={<Getphotomedia1 />} />
        <Route path="/gooffset" element={<Getoffsetphoto1 />} />
        <Route path="/gosocial" element={<Getphotosocial1 />} />
        <Route path="/visit" element={<Getpphotograph1 />} />

        <Route path="/addphotoabout" element={<Addphotoaboat />} />
        <Route path="/changephotoaboat/:id" element={<Editphotoabout />} />

        <Route path="/changephotoemail/:id" element={<Editphotoemail />} />
        <Route path="/addphotoaemail" element={<Addphotoemail />} />
        <Route path="/getphotoaboat" element={<Getphotoabout />} />
        <Route path="/getphotoemail" element={<Getphotoemail />} />
        



        

        <Route path="/login" element={<Login />} />

        
        
        
        

        

        


        

        
        
        
        


        

        {/** web &  app */}
        {/**photo */}
        <Route path="/addphototoapp" element={<Addphotoapp />} />
        <Route path="/changephotoapp/:id" element={<Editphotoapp />} />
        {/** video */}
        <Route path="/addvideooapp" element={<Addvideoapp />} />
        <Route path="/changevideoapp/:id" element={<Editvideo />} />
        {/**link */}
        <Route path="/addlinkapp" element={<Adddlinkapp />} />

         {/** decor */}
        {/**photo */}
        <Route path="/addphototodecor" element={<Addphotodecor />} />
        <Route path="/changephotodecor/:id" element={<Editphotodecor />} />
        {/** video */}
        <Route path="/addvideoodecor" element={<Addvideodecor />} />
        <Route path="/changevidedecor/:id" element={<Editvideodecor />} />
        {/**link */}
        <Route path="/addlinkdecor" element={<Addlinkdecor />} />

        {/** brand */}
        {/**photo */}
        <Route path="/addphototobrand" element={<Addphotobrand />} />
        <Route path="/changephotobrand/:id" element={<Editphotobrand />} />
        {/** video */}
        <Route path="/addvideobrand" element={<Addvideobrand />} />
        {/**link */}
        <Route path="/addlinkbrand" element={<Addlinkbrand />} />

        {/** social */}
        {/**photo */}
        <Route path="/addphototosocial" element={<Addphotosocial />} />
        <Route path="/changephotosocial/:id" element={<Editphotosocial />} />
        {/** video */}
        <Route path="/addvideoosocial" element={<Addvideosocail />} />
        <Route path="/changevideosocial/:id" element={<Editvideosocial />} />
        {/**link */}
        <Route path="/addlinksocial" element={<Addlinksocial />} />

        {/** photography */}
        {/**photo */}
        <Route path="/addphototoapp1" element={<Addphotophotograpgy />} />
        <Route path="/changephotoapp1/:id" element={<Editphotophotograpgy />} />
        {/** video */}
        <Route path="/addvideooapp1" element={<Addvideophotograpgy />} />
        <Route path="/changevideoapp1/:id" element={<Edidvideophotography />} />
        {/**link */}
        <Route path="/addlinkapp1" element={<Adddlinkphotograpgy />} />


        {/** Media */}
        {/**photo */}
        <Route path="/addphototomedia" element={<Addphotomedia />} />
        <Route path="/changephotomedia/:id" element={<Editphotomedia />} />
        {/** video */}
        <Route path="/addvideomedia" element={<Addvideomedia />} />
        <Route path="/changevideomedia/:id" element={<Editivedeomedia />} />
        {/**link */}
        <Route path="/addlinkmedia" element={<Addlinkmedi />} />

         {/** door */}
        {/**photo */}
        <Route path="/addphototodoor" element={<Addphotodoor />} />
        <Route path="/changephotodoor/:id" element={<Editphotodoor />} />
        {/** video */}
        <Route path="/addvideodoor" element={<Addvideodoor />} />
        <Route path="/changevideomedia/:id" element={<Editivedeomedia />} />
        {/**link */}
        <Route path="/addlinkdoor" element={<Addlinkdoor />} />


        {/** gift */}
        {/**photo */}
        <Route path="/addphototogift" element={<Addphotogift />} />
        <Route path="/changephotogift/:id" element={<Editphotogift />} />
        {/** video */}
        <Route path="/addvideogift" element={<Addvideogift />} />
        <Route path="/changevideomedia/:id" element={<Editivedeomedia />} />
        {/**link */}
        <Route path="/addlinkgift" element={<Addlinkgift />} />

 {/** offset */}
        {/**photo */}
        <Route path="/addphototooffset" element={<Addphotooffest />} />
        <Route path="/changephotooffset/:id" element={<Editphotooffest />} />
        {/** video */}
        <Route path="/addvideooffset" element={<Addvideooffest />} />
        <Route path="/changevideomedia/:id" element={<Editivedeomedia />} />
        {/**link */}
        <Route path="/addlinkoffset" element={<Addlinkoffest />} />

 {/***************************** Section *********************/}
 
 {/** brand */}
        {/**photo */}
        <Route path="/addphotosectionbrand" element={<Addphotosectionbrand />} />

{/** decor */}
        {/**photo */}
        <Route path="/addphotosectiondecor" element={<Addphotosectiondecor />} />
{/** web */}
        {/**photo */}
        <Route path="/addphotosectionweb" element={<Addphotosectionweb />} />
{/** social */}
        {/**photo */}
        <Route path="/addphotosectionsocial" element={<Addphotosectionsocial />} />
{/** media */}
        {/**photo */}
        <Route path="/addphotosectionmedia" element={<Addphotosectionmedia />} />

{/** photography */}
        {/**photo */}
        <Route path="/addphotosectionphoto" element={<Addphotosectionphotography />} />


{/** Offset */}
        {/**photo */}
        <Route path="/addphotosectionoffset" element={<Addphotosectionoffset />} />
        

{/** Gift */}
        {/**photo */}
        <Route path="/addphotosectiongift" element={<Addphotosectionggift />} />
        

{/** door */}
        {/**photo */}
        <Route path="/addphotosectiondoor" element={<Addphotosectiondoor />} />



 {/***************************** sendmessage WhatsApp *********************/}

        <Route path="/sendmeaasage/:nam/:pice/:details/:number" element={<Sendmessagewhats />} /> {/** poposl */}
        <Route path="/sendmeaasage/:nampack/:picepack" element={<Sendmassagewhatspackage />} /> {/** package */}
        
 {/***************************** thanks *********************/}
        <Route path="/thanks" element={<Thanks />} /> 
        

 {/***************************** section automaic dashboud *********************/}
 showsonsection

 <Route path="/editsectionwok/:id" element={<Editsectionwok />} />
 <Route path="/addsectionwok" element={<Addsectionwok />} />
 <Route path="/viewsonsection/:id" element={<Getsonsection />} />

 {/****** son section automaic *********/}

 <Route path="/editsonsectionwok/:id" element={<Editsonsection />} />
 <Route path="/addsonsectionwok/:id" element={<Addsonsection />} />
 <Route path="/viewsonsectionall/:id" element={<Getsonsectionphoto />} />

 
 {/****** son section automaic photo *********/}

 <Route path="/changephotosonsection/:id" element={<Editsonsectionphoto />} />
 <Route path="/addphotosonsectionwok/:id" element={<Addsonsectionphoto />} />
 
 {/****** son section automaic link *********/}

 <Route path="/changelinksonsection/:id" element={<Editsonsectionlink />} />
 <Route path="/addlinksonsection/:id" element={<Addsonsectionlink />} />
 {/****** son section automaic video *********/}

 <Route path="/Editvideosonsection/:id" element={<Editsonsectionvideo />} />
 <Route path="/addvideosonsectionwok/:id" element={<Addsonsectionvideo />} />
        
        
 {/***************************** section automaic dashboud *********************/}
 showsonsection

<Route path="/editsectionwok/:id" element={<Editsectionwok />} />
<Route path="/addsectionwok" element={<Addsectionwok />} />
<Route path="/viewsonsection/:id" element={<Getsonsection />} />

{/****** son section automaic *********/}

<Route path="/editsonsectionwok/:id" element={<Editsonsection />} />
<Route path="/addsonsectionwok/:id" element={<Addsonsection />} />
<Route path="/viewsonsectionall/:id" element={<Getsonsectionphoto />} />


{/****** son section automaic photo *********/}

<Route path="/changephotosonsection/:id" element={<Editsonsectionphoto />} />
<Route path="/addphotosonsectionwok/:id" element={<Addsonsectionphoto />} />

{/****** son section automaic link *********/}

<Route path="/changelinksonsection/:id" element={<Editsonsectionlink />} />
<Route path="/addlinksonsection/:id" element={<Addsonsectionlink />} />
{/****** son section automaic video *********/}

<Route path="/Editvideosonsection/:id" element={<Editsonsectionvideo />} />
<Route path="/addvideosonsectionwok/:id" element={<Addsonsectionvideo />} />
       
       
 {/***************************** section automaic dashboud *********************/}
 showsonsection

<Route path="/editsectionwok/:id" element={<Editsectionwok />} />
<Route path="/addsectionwok" element={<Addsectionwok />} />
<Route path="/viewsonsection/:id" element={<Getsonsection />} />

{/****** son section automaic *********/}

<Route path="/editsonsectionwok/:id" element={<Editsonsection />} />
<Route path="/addsonsectionwok/:id" element={<Addsonsection />} />
<Route path="/viewsonsectionall/:id" element={<Getsonsectionphoto />} />


{/****** son section automaic photo *********/}

<Route path="/changephotosonsection/:id" element={<Editsonsectionphoto />} />
<Route path="/addphotosonsectionwok/:id" element={<Addsonsectionphoto />} />

{/****** son section automaic link *********/}

<Route path="/changelinksonsection/:id" element={<Editsonsectionlink />} />
<Route path="/addlinksonsection/:id" element={<Addsonsectionlink />} />
{/****** son section automaic video *********/}

<Route path="/Editvideosonsection/:id" element={<Editsonsectionvideo />} />
<Route path="/addvideosonsectionwok/:id" element={<Addsonsectionvideo />} />
       
       
 {/***************************** section automaic dashboud *********************/}
 
<Route path="/editsectionwok/:id" element={<Editsectionwok />} />
<Route path="/editsectionwokphoto/:id" element={<Editsectionphotophotoonl />} />
<Route path="/addsectionwok" element={<Addsectionwok />} />
<Route path="/viewsonsection/:id" element={<Getsonsection />} />

{/****** son section automaic *********/}

<Route path="/editsonsectionwokphoto/:id" element={<Editsonsectionphotophoto />} />
<Route path="/editsonsectionwok/:id" element={<Editsonsection />} />
<Route path="/addsonsectionwok/:id" element={<Addsonsection />} />
<Route path="/viewsonsectionall/:id" element={<Getsonsectionphoto />} />


{/****** son section automaic photo *********/}

<Route path="/changephotosonsection/:id" element={<Editsonsectionphoto />} />
<Route path="/changenamesonsection/:id" element={<Editsonsectionname />} />
<Route path="/addphotosonsectionwok/:id" element={<Addsonsectionphoto />} />

{/****** son section automaic link *********/}

<Route path="/changelinksonsection/:id" element={<Editsonsectionlink />} />
<Route path="/addlinksonsection/:id" element={<Addsonsectionlink />} />
{/****** son section automaic video *********/}

<Route path="/Editvideosonsection/:id" element={<Editsonsectionvideo />} />
<Route path="/addvideosonsectionwok/:id" element={<Addsonsectionvideo />} />
       
             
 {/***************************** section automaic galla *********************/}
 
<Route path="/showsonsection/:id" element={<Getsonsection1 />} />
<Route path="/showsonsectiondata/:id" element={<Getsonsectionphoto1 />} />








        

        
        
        



      


        








        

        






        




      </Routes>
      <ShowNavbar>
      <Footer />
      </ShowNavbar>
    </div>
  );
}

export default App;
