import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Sum from './products/details/Sum';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Sendmassagewhatspackage = (props) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation(); 
 useEffect(()=>{
  window.scrollTo(0, 0)
 }, [pathname])


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [data, setData] = useState('');
 //   const [id, setId] = useState(0);
    let navigt = useNavigate();
    let {nampack} = useParams(); 
    let {picepack} = useParams();
    


    const  formSubmit = (e)=>{
            e.preventDefault()
            axios.post("https://backend.dasta.net/api/auth/sendemail",{
                name,
                email,
                phone,
                data : nampack ,
                price : picepack, 
           }).then((data)=>{
                navigt('/thanks');
            })
        }  

        // function About() {
        //     // 👇️ redirect to external URL
            
        //     window.location.replace('https://wa.link/5li2nf');
          
        //     return null;
        //   }
    return(
        
        <>
        <div  style={{
                     display: 'grid',
                     textAlign:'center',
                     marginTop:'50px',
                     marginBottom: '50px',
                 }}>
            <form onSubmit={formSubmit} style={{
                placeSelf: 'center'
            }}>
                <label for="fname" >{t('name')} :</label>
                <input type="text" id="fname" name="fname" onChange={(e)=>setName(e.target.value)}/>
             
                <label for="fname" >{t('email')}</label>
                <input type="text" id="fname" name="fname" onChange={(e)=>setEmail(e.target.value)}/>
                <label for="fname" >{t('phone')}</label>
                <input type="text" id="fname" name="fname" onChange={(e)=>setPhone(e.target.value)}/>
                <button type='submit' >Send</button>
             </form>
             <br/> 
             <br/> 
             {/* <br/> 
             <button>
                <Link  onClick={()=>About()} >aaaaaaaa</Link>
                
             </button> */}
        </div>
        </>
        
    )
}
export default Sendmassagewhatspackage;