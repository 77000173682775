import React from 'react';
import Controlproduct1 from './Controlproduct1';
//import Details from '../details/Details';

const Controlproduct = () => {
    return(
        <>
        <Controlproduct1 />
        </>
    )
}
export default Controlproduct;