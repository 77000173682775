import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { DefaultPlayer as Video } from 'react-html5video';
import { MdOutlineSlowMotionVideo } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import 'react-html5video/dist/styles.css';




const Getsonsectionvideo = () =>{
 
    const [ data, setData] = useState([]);
    const [open, setIsOpen] = useState(false);
    const [runVideo, setIsRunVideo] = useState(false);
    const { t, i18n } = useTranslation();
    let {id}  = useParams();




    useEffect(() => {
         fetch(`https://backend.dasta.net/api/auth/getsonbelongtosectionvideo/${id}`)
        .then((res)=>res.json())
        .then((data)=>{
          console.log(data);
          setData(data);
          });
      }, []);

      const delet = (idd) => {         
        fetch(`https://backend.dasta.net/api/auth/deleteworksectionsonControllervideo/${idd}`,{
            method: "DELETE"
        })
        .then((res) => res.json())
       }

    return(
        <>
        <br/>
        <br/>
        <br/>
        <br/>
         <Link
        className="link"
        to = {`/addvideosonsectionwok/${id}`}
        onClick={() => {
        setIsOpen(false);
        }}
        >
        <Button className='buttonn' variant="success">Add New Video</Button>
        </Link>
        <br/>
        <br/>
        <br/>

        <div className='up-Avideo' >
            {
                data.map((data)=>{
                    return(
                        <>
                            <div className='A-video'>
                                <Video>
                                    <source src={"https://backend.dasta.net/brand/"+ data.photo} type='video/webm'/>
                                </Video>
                                <div>
                                    <h5 style={{
                                  textAlign: 'center',
                                  color: 'whitesmoke',
                                  width:'80%',
                                  wordWrap:'break-word',
                                  fontFamily:'cursive',
                                }}>
                                        {data.name}
                                    </h5>
                                </div>
                                <Link
                                className="link"
                                to = {`/Editvideosonsection/${data.id}`}
                                onClick={() => {
                                setIsOpen(false);
                                }}
                                >
                                <Button className='buttonn' variant="success">Edit</Button>
                                </Link>
                                <Button className='button ' variant='success' onClick={ () => delet(data.id)} >delete</Button>
              
                            </div>
                        </>
                    )
                })
            }

        </div>
        </>
    )
}

export default Getsonsectionvideo;
