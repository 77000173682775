import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Button } from 'react-bootstrap';


const Getsectionwok  = () => {
    const [open, setIsOpen] = useState(false);
    const [data, setData] = useState([]);

  
    useEffect(() => {
        section();
     
     }, []);

     const section = () => {
      fetch("https://backend.dasta.net/api/auth/getsectionwokautomatic")
     .then((res)=>res.json())
     .then((data)=>{
       console.log(data);
       setData(data);
       });
     }


       const deleteSection = (id) => {         
        fetch(`https://backend.dasta.net/api/auth/deletesectionwokautomatic/${id}`,{
            method: "DELETE"
        })
        .then((res) => res.json())
       }




    return( 
<>

        {
            data.map((data)=>{
                return(
                    <>

                            
                        <div className="service" >
                            <br/>
                            <br/>
                            <h2 style={{
                                textAlign:'center',
                                width: '250px',
                                 wordWrap:'break-word',

                                 }}>{data.name}</h2>

                            <div className="gallery" >
                                <img
                                src={"https://backend.dasta.net/brand/"+ data.photo  }

                                alt=""
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    borderRadius: '10px',
                                }}
                                />
                            </div>


                            <Link
                            className="link"
                            to = {`/viewsonsection/${data.id}`}
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            >
                                <button className ='btn' > View </button>
                            </Link>

                            
                            
                            <Link
                            className="link"
                            to = {`/editsectionwok/${data.id}`}
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            >
                                <button className ='btn' > Edit name  </button>
                            </Link>
                            <Link
                            className="link"
                            to = {`/editsectionwokphoto/${data.id}`}
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            >
                                <button className ='btn' > Edit photo  </button>
                            </Link>

                            <Link
                            className="link"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            >
                                <button className ='btn' onClick={ () => deleteSection(data.id)}> Delete  </button>
                            </Link>
        
                        </div>
                    </>
                )
            })
        }
        </>

    )

}
export default Getsectionwok;