import { useEffect } from 'react';
import { useState } from 'react';
import './Addcatetegery.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Addcatetegery = () => {
    const [name, setName] = useState('');
    const [name_ar, setName_ar] = useState('');
 //   const [id, setId] = useState(0);
    let navigt = useNavigate();


    const  formSubmit = (e)=>{
            e.preventDefault()
            axios.post("https://backend.dasta.net/api/auth/addcate",{
                name,
                name_ar,
            }).then((data)=>{
                navigt('/Controlctegery');
            })
        }  
    return(
        
        <>
            <form onSubmit={formSubmit}>
                <label for="fname" >Name Of Categery:</label>
                <input type="text" id="fname" name="fname" onChange={(e)=>setName(e.target.value)}/>
             
                <label for="fname" >Name Of Categery:ARabic</label>
                <input type="text" id="fname" name="fname" onChange={(e)=>setName_ar(e.target.value)}/>
                
             
                <button type='submit' >add</button>


                {/**
                    <Link
                        className="link"
                        to = {`control/${categers.name}/${categers.id}`}
                        onClick={() => {
                        setIsOpen(false);
                        }}
                    >

                    </Link> */}
             </form> 
        </>
        
    )
}
export default Addcatetegery;