import React, { useEffect, useState } from 'react';
import './services.css';
import { RiAdminFill } from 'react-icons/ri';
import { HiBuildingStorefront } from 'react-icons/hi2';
import { SiCoronaengine } from 'react-icons/si';
import { GiOrganigram } from 'react-icons/gi';
import { IoShareSocialSharp } from 'react-icons/io5';
import { FaAdversal } from 'react-icons/fa';
import { TbBrandPython } from 'react-icons/tb';
import { AiFillCamera } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/8dab4f88a129879185f50e7ed22aa8a2whats-removebg-preview (1).png';


import { Link, useNavigate } from 'react-router-dom';
const Services = (props) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation(); 
 useEffect(()=>{
  window.scrollTo(0, 0)
 }, [pathname])


  const { setServiceId } = props;
  const servicesData = [
    {
      icon: RiAdminFill,
      link: t('ser1'),
      par: t('ser1des'),
      id: 1,
    },
    {
      icon: TbBrandPython,
      link: t('ser2'),
      par: t('ser2des'),
      id: 7,
    },

    {
      icon: FaAdversal,
      link: t('ser3'),
      par: t('ser3des'),
      id: 6,
    },

    {
      icon: IoShareSocialSharp,
      link: t('ser4'),
      par: t('ser4des'),
      id: 5,
    },
    {
      icon: SiCoronaengine,
      link: t('ser5'),
      par: t('ser5des'),
      id: 4,
    },
    {
      icon: HiBuildingStorefront,
      link: t('ser6'),
      par: t('ser6des'),
      id: 3,
    },
    {
      icon: AiFillCamera,
      link: t('ser7'),
      par: t('ser7des'),
      id: 2,
    },
    {
      icon: GiOrganigram,
      link: t('ser8'),
      par: t('ser8des'),
      id: 8,
    },
  ];
  const [serviceData, setServiceData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.getItem('service');
  }, []);

  
  function link() {
    //  👇️// redirect to external URL
     
      window.location.replace('https://wa.link/fiolo6');
   
     return null;
   }
  return (
    <div>
      <div className="general-background-image ser">
        <div className="back">
          <h2>{t('services')}</h2>
          <Link className="link back-link" to="/">
            {t('home')}
          </Link>
        </div>
      </div>

      <div className="services" id="services">
        <div className="container services-container">
          {servicesData.map((service) => {
            return (
              <div className="service" style={{
                paddingLeft: '50px',
                paddingRight:'50px',
              }}>
                <div>
                  <service.icon
                    style={{
                      fontSize: '50px',
                      marginTop: '70px',
                      color: '#52adb8',
                    }}
                  />
                  <Link className="link-service">{service.link}</Link>
                </div>
                <p>{service.par}</p>
                <Link className='more' to={`/services/${service.id}/?par=${service.par}&link=${service.link}`}>              
                  <button
                    className="more"
                    onClick={() => {
                      setServiceId(service.id);
                    }}
                  >
                    {t('more')}
                  </button>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      
      <img src={logo} alt='logo' 
            style={{ 
              width :'100px',
              height:'100px',
              position: 'fixed',
              zIndex: '10',
              top: '85%',
              right:'5px',
              cursor:'pointer',
              
             }}
             onClick={()=>link()}
             />
    </div>
  );
};

export default Services;

                  
// navigate(
//   `/services/${service.id}/?par=${service.par}&link=${service.link}`
// );
 