import React from 'react';
import { Link, useLocation, useNavigate, NavLink, json } from 'react-router-dom';
import './navbar.css';
import { BsList } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';



const Navbar = () => {
  const [open, setIsOpen] = useState(false);
  const [active, setActive] = useState('home');
  const navigate = useNavigate();
  let { state } = useLocation();
  const { t, i18n } = useTranslation();
let lang;  

  const [categers, setCategers]= useState([]);
   useEffect(()=>{
     fetch('https://backend.dasta.net/api/auth/fetchcate')
     .then((res)=>res.json())
     .then((data)=>{
       console.log(data);
       setCategers(data);
       })
   }, [])



   if(i18n.language=='en'){
    lang=categers.map( (categers) => {
      return( 
        <li key ={categers.id} >
        <Link
          className="link"
          to = {`/${categers.name}/${categers.id}`}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          { categers.name }
         {/* <Collection categers = { categers } />*/}
          <hr/>
        </Link>
      
        </li>
      );
      
    })
   }else{
    lang=categers.map( (categers) => {
      return( 
        <li key ={categers.id} >
        <Link
          className="link"
          to = {`/${categers.name_ar}/${categers.id}`}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          { categers.name_ar }
         {/* <Collection categers = { categers } />*/}
          <hr/>
        </Link>
      
        </li>
      );
      
    })
   }
  return (
    <nav>
      <div
        className="head"
        onClick={() => {
          navigate('/');
          setIsOpen(false);
        }}
      >
        <h2>
          <span className="five">{t('d')}</span>
          {t('asta')}
          <span className="five">{t('a')}</span>
          {t('gency')}
        </h2>
        <h4>{t('logoService')}</h4>
      </div>
      {!open ? (
        <BsList
          className={`icon ${!open && 'open-icon'}`}
          onClick={() => setIsOpen(true)}
        />
      ) : (
        <AiOutlineClose
          onClick={() => setIsOpen(false)}
          className={`icon ${open && 'open-icon'}`}
        />
      )}
      <ul className={`main-ul ${open && 'open'}`}>
        <li>
          <Link
            onClick={() => {
              setActive('home');
              setIsOpen(false);
            }}
            className={`link ${active === 'home' ? 'active' : ''}`}
            to="/"
          >
            {t('home')}
          </Link>
        </li>
        <li>
          <NavLink
            onClick={() => {
              setActive('about');
              setIsOpen(false);
            }}
            className={`link ${active === 'about' ? 'active' : ''}`}
            to="/about"
          >
            {t('about')}
          </NavLink>
        </li>

        <li>
          <Link
            onClick={() => {
              setActive('services');
              setIsOpen(false);
            }}
            className={`link ${active === 'services' ? 'active' : ''}`}
            to="/services"
          >
            {t('services')}
          </Link>
        </li>





        <li className="pages">
          <Link
            onClick={() => {
              setActive('pages');
            }}
            className={`link ${active === 'pages' ? 'active' : ''}`}
            to="#prod"
          >
            {t('products')}
          </Link>

          <ul className="pages-ul" id="paUl">


            {lang} 

{/* 
 <li>
              <Link
                className="link"
                to="/brand"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t('brand')}
              </Link>
            </li> */}



{/*
            <li>
              <Link
                className="link"
                to="/social"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t('social')}
              </Link>
            </li>
            <hr />

            <li>
              <Link
                className="link"
                to="/webapp"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t('web')}
              </Link>
            </li>
            <hr />

            <li>
              <Link
                className="link"
                to="/media"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t('media')}
              </Link>
            </li>
            <hr />

            <li>
              <Link
                className="link"
                to="/photovideos"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t('photos')}
              </Link>
            </li>
            <hr />

            <li>
              <Link
                className="link"
                to="/offset"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t('offset')}
              </Link>
            </li>
            <hr />

            <li>
              <Link
                className="link"
                to="/outindoor"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t('outin')}
              </Link>
              </li> 
              */}
          </ul>
        </li>
        {/* <li>
          <a
            onClick={() => setActive('events')}
            className={`link ${active === 'events' ? 'active' : ''}`}
            href="#events"
          >
            TEAM
          </a>
        </li> */}
        <li>
          <Link
            onClick={() => {
              setActive('gallery');
              setIsOpen(false);
            }}
            className={`link ${active === 'gallery' ? 'active' : ''}`}
            to="/work"
          >
            {t('work')}
          </Link>
        </li>

        {/* <li>
          <a
            onClick={() => setActive('blog')}
            className={`link ${active === 'blog' ? 'active' : ''}`}
            href="#blog"
          >
            BLOG
          </a>
        </li> */}
        <li>
          <Link
            onClick={() => {
              setActive('contact');
              setIsOpen(false);
            }}
            className={`link ${active === 'contact' ? 'active' : ''}`}
            to="/contact"
          >
            {t('contact')}
          </Link>
        </li>
        <li className="lan">
          <Link className="link">{t('languages')}</Link>
          <ul className="lan-ul">
            <li>
              <Link
                className="link"
                onClick={() => {
                  i18n.changeLanguage('ar');
                  setIsOpen(false);
                }}
              >
                {t('ar')}
              </Link>
            </li>
            <hr />
            <li>
              <Link
                className="link"
                onClick={() => {
                  i18n.changeLanguage('en');
                  setIsOpen(false);
                }}
              >
                {' '}
                {t('en')}
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
