import React from 'react';
import './home.css';
import Land from './land/Land';
import HomeGallery from './homeGallery/HomeGallery';
import TeamWork from './homeWork/TeamWork';
import HomeStratgy from './home-strategy/HomeStratgy';
import About from '../about/About';
import HomeAbout from './home-about/HomeAbout';
import HomeContact from './home-contact/HomeContact';
import HomeStr from './home-str/HomeStr';
import HomeVideo from './home-video/HomeVideo';
import logo from '../../assets/8dab4f88a129879185f50e7ed22aa8a2whats-removebg-preview (1).png';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Home = () => {
  
         function link() {
           //  👇️// redirect to external URL
            
             window.location.replace('https://wa.link/fiolo6');
          
            return null;
          }
          const { pathname } = useLocation(); 
          useEffect(()=>{
           window.scrollTo(0, 0)
          }, [pathname])
  return (
    <div>
      <Land />
      <HomeAbout />
      <HomeStratgy />
      <HomeStr />
      <HomeGallery />
      <TeamWork />
      <HomeContact />
      <HomeVideo />
      <div>
        <img src={logo} alt='logo' 
            style={{ 
              width :'100px',
              height:'100px',
              position: 'fixed',
              zIndex: '10',
              top: '85%',
              right:'5px',
              cursor:'pointer',
              
             }}
             onClick={()=>link()}
             />  
       </div>
    

    </div>
  );
};

export default Home;
