import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";




const Addvideooffest = () =>{
   const [name, setName] = useState('');
   const [upload, setUpload] = useState(0);

    let navigt = useNavigate();
    const handleImage =(e)=>{
        setName(e.target.files[0]) 
    }
    const  formSubmit = (e)=>{
        e.preventDefault()
        const formData = new FormData()
        formData.append('name', name)
 
        axios.post('https://backend.dasta.net/api/auth/addvideoourworkoffset',formData, {
            onUploadProgress : (data) =>{
                console.log(Math.round((data.loaded/data.total) * 100));
                setUpload(Math.round((data.loaded/data.total) * 100));
                
            },
           }).then((res)=>{
            console.log(res)
        }).then((data)=>{
            navigt(-1);
        })
    }  

    return(
        <>
        <form onSubmit={formSubmit}>
            <input type="file" name="file" onChange={(e)=>setName(e.target.files[0])}/>
            <Progress percent={`${upload}`} 
            role='progressbar'
            aria-valuenow={upload}
            aria-valuemin='0'
            aria-valuemax='100'
            style={{width: `${upload}%`, color: 'white'}}
            />
            <button type='submit' >submit</button>
        </form>
        </>
    )

}

export default Addvideooffest;