import { useEffect } from 'react';
import { useState } from 'react';
import './Addcatetegery.css';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
const Addproduct = () => {
    const [name, setName] = useState('');
    const [name_ar, setName_ar] = useState('');
 //   const [id, setId] = useState(0);
    const [bands_id, setBands_id] = useState(0);

    let navigt = useNavigate();
    let { cateId } =useParams();
    
    


    const  formSubmit = (e)=>{
            e.preventDefault()
             axios.post("https://backend.dasta.net/api/auth/addband",{
                name,
                name_ar,
                bands_id : cateId,
            }).then((data)=>{
                navigt(-1);
            })
        }  
    return(
        
        <>
            <form onSubmit={formSubmit}>
                <label for="fname" >Name Of Categery:</label>
                <input type="text" id="fname" name="fname" onChange={(e)=>setName(e.target.value)}/>
                                
                                
                <label for="fname" >Name Of Categery:Arabic</label>
                <input type="text" id="fname" name="fname" onChange={(e)=>setName_ar(e.target.value)}/>
{/*                                 
                <label for="lname">Please Enter this number: {cateId}</label>
                <input type="text" id="lname" name="lname"  onChange={(e)=>setBands_id(e.target.value)}/>
                 */}
                <button type='submit' >add</button>
                
             </form> 
        </>
        
    )
}
export default Addproduct;