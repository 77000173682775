import React, { useEffect, useState } from 'react';
import './gallery.css';
import { Link } from 'react-router-dom';
import image1 from '../../assets/one.jpg';
import image2 from '../../assets/two.jpg';
import image3 from '../../assets/three.jpg';
import image4 from '../../assets/four.jpg';
import image5 from '../../assets/five.jpg';
import image6 from '../../assets/six.jpg';
import image7 from '../../assets/seven.jpg';
import image8 from '../../assets/eight.jpg';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import Getsectionwok1 from './sectionwok/Getsectionwok1';

const Gallery = () => {

  // const [open, setIsOpen] = useState(false);
  // const [model, setModel] = useState(false);
  // const [temimgSrc, setTemImgSrc] = useState('');
   const { t, i18n } = useTranslation();
  
  // const [data1, setData1] = useState([]);
  // const [data2, setData2] = useState([]);
  // const [data3, setData3] = useState([]);
  // const [data4, setData4] = useState([]);
  // const [data5, setData5] = useState([]);
  // const [data6, setData6] = useState([]);
  // const [data7, setData7] = useState([]);
  // const [data8, setData8] = useState([]);
  // const [data9, setData9] = useState([]);

  
  //    const getImage = (imageSrc) => {
  //    setTemImgSrc(imageSrc);
  //    setModel(true);
  //  };
  
 
  // //const [collection, setCollection] = useState([]);

  
  // useEffect(() => {
  //   brand();
  //   decor();
  //   door();
  //   gift();
  //   media();
  //   offset();
  //   photo();
  //   social();
  //   web();
      
  //  }, []);
  //  const brand = () => {
  //   fetch("https://backend.dasta.net/api/auth/getphotoourworkbandsection")
  //  .then((res)=>res.json())
  //  .then((data)=>{
  //    console.log(data);
  //    setData1(data);
  //    });
  //  }

  //  const decor = () => {
  //   fetch("https://backend.dasta.net/api/auth/getphotoourworkdecorsection")
  //  .then((res)=>res.json())
  //  .then((data)=>{
  //    console.log(data);
  //    setData2(data);
  //    });
  //  }

  //  const door = () => {
  //   fetch("https://backend.dasta.net/api/auth/getphotoourworkdoorsection")
  //  .then((res)=>res.json())
  //  .then((data)=>{
  //    console.log(data);
  //    setData3(data);
  //    });
  //  }

  //  const gift = () => {
  //   fetch("https://backend.dasta.net/api/auth/getphotoourworkgiftsection")
  //  .then((res)=>res.json())
  //  .then((data)=>{
  //    console.log(data);
  //    setData4(data);
  //    });
  //  }

  //  const media = () => {
  //   fetch("https://backend.dasta.net/api/auth/getphotoourworkmediasection")
  //  .then((res)=>res.json())
  //  .then((data)=>{
  //    console.log(data);
  //    setData5(data);
  //    });
  //  }

  //  const offset = () => {
  //   fetch("https://backend.dasta.net/api/auth/getphotoourworkoffsetsection")
  //  .then((res)=>res.json())
  //  .then((data)=>{
  //    console.log(data);
  //    setData6(data);
  //    });
  //  }

  //  const photo = () => {
  //   fetch("https://backend.dasta.net/api/auth/getphotoourworkphotographysection")
  //  .then((res)=>res.json())
  //  .then((data)=>{
  //    console.log(data);
  //    setData7(data);
  //    });
  //  }

  //  const social = () => {
  //   fetch("https://backend.dasta.net/api/auth/getphotoourworksocialsection")
  //  .then((res)=>res.json())
  //  .then((data)=>{
  //    console.log(data);
  //    setData8(data);
  //    });
  //  }

  //  const web = () => {
  //   fetch("https://backend.dasta.net/api/auth/getphotoourworkwebsection")
  //  .then((res)=>res.json())
  //  .then((data)=>{
  //    console.log(data);
  //    setData9(data);
  //    });
  //  }




  return (
    <div>
      <div className="general-background-image gal">
        <div className="back">
          <h2>{t('work')}</h2>
          <Link className="link back-link" to="/">
            {t('home')}
          </Link>
        </div>
      </div>

      <div className="services" id="services">
        <div className="container services-container">
{/* 
           <div className="service">
            <br/>
            <br/>
              <h2>Branding</h2>
          {
            data1.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
              </div>  
              )
            })
          } 


              <Link
              className="link"
              to = '/gobrand'
              onClick={() => {
                setIsOpen(false);
                }}
                >
                  <h2> See Gallery  </h2>
              </Link>
           </div>

           <div className="service">
            <br/>
            <br/>
              <h2>Decor</h2>

              {
            data2.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
              </div>  
              )
            })
          } 



              <Link
              className="link"
              to = '/godecor'
              onClick={() => {
                setIsOpen(false);
                }}
                >
                  <h2> See Gallery  </h2>
              </Link>
           </div>

           <div className="service">
            <br/>
            <br/>
              <h2>Web & APP</h2>
              
          {
            data9.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
              </div>  
              )
            })
          } 

              <Link
              className="link"
              to = '/goweb'
              onClick={() => {
                setIsOpen(false);
                }}
                >
                  <h2> See Gallery  </h2>
              </Link>
           </div>


           
           <div className="service">
            <br/>
            <br/>
              <h2>Social Media</h2>
              {
            data8.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
              </div>  
              )
            })
          } 

              <Link
              className="link"
              to = '/gosocial'
              onClick={() => {
                setIsOpen(false);
                }}
                >
                  <h2> See Gallery  </h2>
              </Link>
           </div>


           
           <div className="service">
            <br/>
            <br/>
              <h2>Media & Design</h2>
              
          {
            data5.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
              </div>  
              )
            })
          } 

              <Link
              className="link"
              to = '/gomedia'
              onClick={() => {
                setIsOpen(false);
                }}
                >
                  <h2> See Gallery  </h2>
              </Link>
           </div>

           
           <div className="service">
            <br/>
            <br/>
              <h2>photography</h2>
              
          {
            data7.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
              </div>  
              )
            })
          } 
              <Link
              className="link"
              to = '/visit'
              onClick={() => {
                setIsOpen(false);
                }}
                >
                  <h2> See Gallery  </h2>
              </Link>
           </div>


           
           <div className="service">
            <br/>
            <br/>
              <h2>Offset Prints</h2>
              
          {
            data6.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
              </div>  
              )
            })
          } 

              <Link
              className="link"
              to = '/gooffset'
              onClick={() => {
                setIsOpen(false);
                }}
                >
                  <h2> See Gallery  </h2>
              </Link>
           </div>



           <div className="service">
            <br/>
            <br/>
              <h2>Gift Items</h2>
              
          {
            data4.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
              </div>  
              )
            })
          } 

              <Link
              className="link"
              to = '/gogift'
              onClick={() => {
                setIsOpen(false);
                }}
                >
                  <h2> See Gallery  </h2>
              </Link>
           </div>





           
           <div className="service">
            <br/>
            <br/>
              <h2>InDoor & OutDoor</h2>
              
          {
            data3.map((data)=>{
              return(
                <div className="gallery" >
                <img
                src={"https://backend.dasta.net/brand/"+ data.name  }

                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                  }}
                />
                
              </div>  
              )
            })
          } 

              <Link
              className="link"
              to = '/godoor'
              onClick={() => {
                setIsOpen(false);
                }}
                >
                  <h2> See Gallery  </h2>
              </Link>
           </div> */}

              <Getsectionwok1/>


           
        </div>
      </div> 
    </div>
  );
};

export default Gallery;


{/**
<div className="galleries" id="gallery">
        <h2 className="general-header">{t('work')}</h2>
        <hr className="general-hr" />
        <div className="filterPictures">
          <ul>

            <li>
              <Link
                // to = {<Getphotobrand1/>}
              >
                <button className = 'gallery-li-button' > Brand </button>
              </Link>
            </li>
          </ul>
        </div>
     {/*   <div className="container galleries-container">
          <div className={model ? 'model open' : 'model'}>
            <img src={temimgSrc} alt="" />
            <CloseIcon onClick={() => setModel(false)} />
          </div>

          {galleryData.map((data, index) => {
            return (
              <div className="gallery" key={index}>
                <img
                  src={data.image}
                  alt=""
                  onClick={() => getImage(data.image)}
                />

                 <Link className=" gallery-link">{data.link2}</Link> 
              </div>
            );
          })}
        </div> ///
        </div>

*/}
