import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
const Editsonsectionlink = () => {
    const [name, setName] = useState('');
    const [photo, setPhoto] = useState('');
    let navigt = useNavigate();
    let {id}=useParams();


    const  formSubmit = (e)=>{
            e.preventDefault()
            axios.post(`https://backend.dasta.net/api/auth/updateworksectionsonControllerlink/${id}`,{
                name,
                photo,
            }).then((data)=>{
                navigt(-1);
            })
        }  
    return(
        
        <>
            <form onSubmit={formSubmit}>
                <label for="fname" >Enter Link:</label>
                <input type="text" id="fname" name="fname" onChange={(e)=>setName(e.target.value)}/>
                <label for="fname" >Enter descrption:</label>
                <input type="text" id="fname" name="fname" onChange={(e)=>setPhoto(e.target.value)}/>
                
                <button type='submit' >add</button>
             </form> 
        </>
        
    )
}
export default Editsonsectionlink;