import { useEffect } from 'react';
import { useState } from 'react';
import './Addcatetegery.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Addtotal = () =>{
    const [sum_id, setSum_id] = useState(0);
    const [sum, setSum] = useState(0);

   // const [id, setId] = useState(0);

    const [details_id, setDetails_id] = useState(0);

    let { bandId } = useParams();
    let navigt = useNavigate();


    const  formSubmit = (e)=>{
            e.preventDefault()
            axios.post("https://backend.dasta.net/api/auth/adddesum",{
                sum,
                sum_id : bandId,

            }).then((data)=>{
                navigt(-1);
            })

            
        }  
    return (
        <>
        
        <form onSubmit={formSubmit}>
            <label for="lname">Price:</label>
            <input type="text" id="lname" name="lname"  onChange={(e)=>setSum(e.target.value)}/>
            
            {/* <label for="lname">PLEASE ENTER THIS NUMBER {bandId}</label>
            <input type="text" id="lname" name="lname"  onChange={(e)=>setSum_id(e.target.value)}/>
             */}
            <button type='submit' >add</button>


            {/**
                <Link
                    className="link"
                    to = {`control/${categers.name}/${categers.id}`}
                    onClick={() => {
                    setIsOpen(false);
                    }}
                >

                </Link> */}
         </form> 
    </>
    )
}  
export default Addtotal;