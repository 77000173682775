import React from 'react';
import './about.css';
import aboutImage from '../../assets/about_home.jpg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/8dab4f88a129879185f50e7ed22aa8a2whats-removebg-preview (1).png';
import logo2 from '../../assets/logo copy.jpg';
import logo3 from '../../assets/about copy.jpg';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const About = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation(); 
 useEffect(()=>{
  window.scrollTo(0, 0)
 }, [pathname])
  function link() {
    //  👇️// redirect to external URL
     
      window.location.replace('https://wa.link/fiolo6');
   
     return null;
   }

  return (
    <div>
      <div className="general-background-image abo" style={{backgroundImage:` linear-gradient( 0deg, hsl(218, 26%, 14%, 0.8), hsl(218, 26%, 14%, 0.8) ), url("${logo3}")`,zIndex:'-10'}}>
        <div className="back">
          <h2>{t('about')}</h2>
          <Link className="link back-link" to="/">
            {t('home')}
          </Link>
        </div>
      </div>
      <div className="about" id="about">
        {/* <h2 className="general-header">{t('about')} </h2> */}
        {/* <hr className="general-hr" /> */}
        <div className="container">
          <div className="about-left">
            <img src={logo2} alt="" />
          </div>
          <div className="about-rigth">
            {/* <p className="about-first-p">
              <span>
                {t('Hi')}, {t('imdasta')}
              </span>
              {t('dastades')}
            </p>
            <p className="about-second-p">
              <span>{t('aboutDes1')}</span>
              {t('aboutDes2')}
            </p> */}
            
            <p className="about-first-p">
              <span>
                {t('abou')}
                <span>{t('t')}</span>
                {t('us')}
              </span>
            </p>

            <p className="about-second-p">
            <span>  <h3>{t('into')}</h3></span>
              <span ><h4>{t('into2')}</h4></span>
              <span>{t('aboutDes1')}</span>
              {t('aboutDes2')}
            </p>
            <button className="about-button">{t('about')}</button>
          </div>
        </div>
      </div>

      
      <img src={logo} alt='logo' 
            style={{ 
              width :'100px',
              height:'100px',
              position: 'fixed',
              zIndex: '10',
              top: '85%',
              right:'5px',
              cursor:'pointer',
              
             }}
             onClick={()=>link()}
             />
    </div>

    
  );
};

export default About;
