import { useEffect } from 'react';
import { useState } from 'react';
import './Addcatetegery.css';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Editdetailswithoutdis = ()=>{
    const [name, setName] = useState('');
    const [name_ar, setName_ar] = useState('');
    const [details, setDetails] = useState('');
    const [details_ar, setDetails_ar] = useState('');
    const [price, setPrice] = useState(0);
    const [number, setNumber] = useState(0);

 //   const [wdetails_id, setWdetails_id] = useState(0);
    let navigt = useNavigate();
    let { detailsId } =useParams();
    let { id } =useParams();



    const  formSubmit = (e)=>{
            e.preventDefault()
            axios.post(`https://backend.dasta.net/api/auth/updatewdetail/${detailsId}`,{
                name,
                name_ar,
                details,
                details_ar,
                price,
                number,
               // wdetails_id,

            }).then((data)=>{
                navigt(-1);
            })
        }  

    return (
        
        <>
        <form onSubmit={formSubmit}>
        <label for="fname" >Name Of Band:</label>
            <input type="text" id="fname" name="fname" onChange={(e)=>setName(e.target.value)}/>
       
            <label for="fname" >Name Of Band:Arabic</label>
            <input type="text" id="fname" name="fname" onChange={(e)=>setName_ar(e.target.value)}/>
       
       
            <label for="lname">Number:</label>
            <input type="text" id="lname" name="lname"  onChange={(e)=>setNumber(e.target.value)}/>
            <label for="lname">Price:</label>
            <input type="text" id="lname" name="lname"  onChange={(e)=>setPrice(e.target.value)}/>
            <label for="lname">Details:</label>
            {/* <input type="text" id="lname" name="lname"  onChange={(e)=>setDetails(e.target.value)}/> */}
            <textarea  rows={4} cols={40} onChange={(e)=>setDetails(e.target.value)}/>            
            
            <label for="lname">Details:Arabic</label>
            {/* <input type="text" id="lname" name="lname"  onChange={(e)=>setDetails(e.target.value)}/> */}
            <textarea  rows={4} cols={40} onChange={(e)=>setDetails_ar(e.target.value)}/>            
            

            <button type='submit' >Update</button>
        </form> 


        </>

    )
}
export default Editdetailswithoutdis;