
import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import {Container , Button, Col} from 'react-bootstrap';

const Generaldetails = () =>{
    let { bandId } =useParams();
    let { id } =useParams();
    const [open, setIsOpen] = useState(false);


    return(
        <>
        
    <div className="services" id="services">
      <div className="container services-container">
        <div className="service">
        <Link
            className="link"
            to = {`/addproductwithdiscount/${bandId}`}
            onClick={() => {
            setIsOpen(false);
            }}
        >
            <h2>
                CREATE PROPOSEL ?? 
            </h2>
        </Link>


        
         
        </div>
        <div className="service">
        <Link
            className="link"
            to = {`/addproductwithoutdiscount/${bandId}/${id}`}
            onClick={() => {
            setIsOpen(false);
            }}
        >
            <h2>
                CREATE PACKAGE   ?? 
            </h2>
        </Link>

        <Link
            className="link"
            to = {`/adddis/${bandId}`}
            onClick={() => {
            setIsOpen(false);
            }}
        >
            <h2>
                ADD DESCOUND   ?? 
            </h2>
        </Link>

        <Link
            className="link"
            to = {`/addtotal/${bandId}`}
            onClick={() => {
            setIsOpen(false);
            }}
        >
            <h2>
                ADD total price    ?? 
            </h2>
        </Link>
        
         
        </div>
      </div>
    </div>

       
        <hr />

        

        
        </>
    )
}
export default Generaldetails;