
import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Addphotomedia = () =>{
    const [name, setName] = useState('');
    let navigt = useNavigate();
    const handleImage =(e)=>{
        setName(e.target.files[0]) 
    }
    const  formSubmit = (e)=>{
        e.preventDefault()
        const formData = new FormData()
        formData.append('name', name)
 
        axios.post('https://backend.dasta.net/api/auth/addphotoourworkmedia',formData).then((res)=>{
            console.log(res)
        }).then((data)=>{
            navigt(-1);
        })
    }  

    return(
        <>
        <form onSubmit={formSubmit}>
            <input type="file" name="file" onChange={(e)=>setName(e.target.files[0])}/>
            <button type='submit' >submit</button>
        </form>
        </>
    )

}
export default Addphotomedia;