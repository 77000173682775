import React from 'react';
import aboutImage from '../../../assets/about_home.jpg';
import './homeAbout.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const HomeAbout = () => {
  const navigate = useNavigate('');
  const { t, i18n } = useTranslation();

  {/**me */}
  
  const [ data, setData] = useState([]);
  const [open, setIsOpen] = useState(false);
  let a;
  
  
  useEffect(() => {
    fetch("https://backend.dasta.net/api/auth/getchangeaboutphoto")
   .then((res)=>res.json())
   .then((data)=>{
     console.log(data);
     setData(data);
     });
 }, []);



  return (
    <div>
      <div className="about home-about" id="about">
        <div className="container">
          <div className="about-left">
            {/* <img src={aboutImage} alt="" /> */}
            {
              data.map((date)=>{
                return(
                  <>
                  <img src = {"https://backend.dasta.net/brand/"+ date.name}/>
                  </>
                )
              })
            }
          </div>
          <div className="about-rigth">
            <p className="about-first-p">
              <span>
                {t('abou')}
                <span>{t('t')}</span>
                {t('us')}
              </span>
            </p>
            <p className="about-second-p">
            <span>  <h3>{t('into')}</h3></span>
              <span ><h4>{t('into2')}</h4></span>
              <span>{t('aboutDes1')}</span>
              {t('aboutDes2')}
            </p>
            <Link 
              to="/about" preventScrollReset={true}
            >
             <button className="about-button" >
              {t('about')}
            </button>
            </Link>


          </div>
        </div>
      </div>
    </div>

    
  );
};

export default HomeAbout;

// onClick={() => navigate('/about')}
