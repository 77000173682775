
const Sum = (props) =>{
    let sum = 0;
    console.log(sum)
    return(
        <>
       {sum = props.sum + sum}
        </>
    )
}
export default Sum;