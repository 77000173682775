import { useEffect } from 'react';
import { useState } from 'react';
import './Addcatetegery.css';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Editcatetegery = () => {
    const [name, setName] = useState('');
    const [name_ar, setName_ar] = useState('');
   // const [idd, setIdd] = useState(0);
    let navigt = useNavigate();
    let { id } =useParams();



    const  formSubmit = (e)=>{
            e.preventDefault()
            axios.post(`https://backend.dasta.net/api/auth/updatecate/${id}`,{
                name,
                name_ar,
            }).then((data)=>{
                navigt('/Controlctegery');
            })
        }  
    return(

        <><form onSubmit={formSubmit}>
        <label for="fname" >Name Of Categery:</label>
        <input type="text" id="fname" name="fname" onChange={(e)=>setName(e.target.value)}/>
        
        
        <label for="fname" >Name Of Categery:Arabic</label>
        <input type="text" id="fname" name="fname" onChange={(e)=>setName_ar(e.target.value)}/>
        

        <button type='submit' >Update</button>
     </form> 


        </>
    )

}

export default Editcatetegery;