import React from 'react';
import { Link, useParams } from 'react-router-dom';
import './service.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Service = (props) => {
  const { t, i18n } = useTranslation();
  const { service } = props;
  const { pathname } = useLocation(); 
 useEffect(()=>{
  window.scrollTo(0, 0)
 }, [pathname])


  const params = new URLSearchParams(window.location.search);
  const par = params.get('par');
  const link = params.get('link');
  const { id } = useParams();
  return (
    <div>
      <div className="general-background-image">
        <div className="back">
          <h2>{link}</h2>
          <Link className="link back-link" to="/services">
            {t('services')}
          </Link>
        </div>
      </div>
      <div className="ser">
        <div className="container">
          <div className="ser-left">
            <p className="link ser-p">{link}</p>
            {(link === 'ADMINISTRATIVE DEVELOPMENT' || id === 1) && (
              <ul className="service-ul">
                <li>Administrative Development</li>
                <li> Setting vision, mission and objectives</li>
                <li>Building strategic plans and development plans</li>
                <li>
                  Building and developing the administrative and organizational
                  structure
                </li>
                <li>
                Developing organizational work regulations and procedural laws
                </li>
                <li>Preparing task lists for employees</li>
                <li>
                  Preparing models for the flow of operations and personnel
                  affairs
                </li>
                <li> Forms of annual plans and operational plans</li>
                <li>
                Preparing evaluation forms for employees and the ideal employee
                </li>
                <li>Create tools to measure institutional and functional performance</li>
                <li>Develop tools to measure the indicators of the progress of operations</li>
                <li>Work on development plans in coordination with marketing plans</li>
                <li> Qualifying companies to obtain administrative quality certificates</li>
              </ul>
            )}
            {link === 'التطوير الإداري' && (
              <ul className="service-ul">
              
                <li>وضع الرؤية والرسالة والأهداف</li>
                <li>التطوير الإداري</li>
                <li>بناء الخطط الاستراتيجية وخطط التتطوير</li>
                <li>بناء وتطوير الهيكل الإداري والتنظيمي</li>
                <li>وضع لوائح العمل التنظيمي والقوانين الاجراءات</li>
                <li>إعداد قوائم المهام للموظفين</li>
                <li>	إعداد نماذج سير العمليات وشؤون العاملين</li>
                <li>نماذج الخطة السنوية والخطة التشغيلية</li>
                <li>إعداد نماذج التقييم الخاصة بالعاملين والموظف المثالي</li>
                <li>	تكوين أدوات لقياس الأداء المؤسسي والوظيفي</li>
                <li>العمل علي خطط التطوير بالتنسيق مع خطط التسويق</li>
                <li>تأهيل الشركات للحصول علي شهادات الجودة الإدارية</li>
                <li>	وضع أدوات لقياس مؤشرات سير العمليات</li>
              </ul>
            )}
            {link === 'PROFESSIONAL PHOTOGRAPHY' && (
              <ul className="service-ul">
                <li  >
                  Videography
                  <ul  >Videos of places</ul>
                  <ul  >Filming interviews and recordings</ul>
                  <ul  >Coverage of events and events</ul>
                </li>
                <li  >
                  photographer
                  <ul  >Photographing places</ul>
                  <ul  >Photographing legal persons</ul>
                  <ul  >Coverage of events and events</ul>
                </li>
                <li  >
                   Photographing products
                   <ul  >Photographing products for sales</ul>
                   <ul  >Photographing products for promotions and advertisements</ul>
                   <ul  >Photographing products for websites and direct selling</ul>
                </li>
                </ul>
            )}
            {link === 'تصوير احترافي' && (
              <ul className="service-ul">
                <li >
                	التصوير الفيديوجرافي
                  <ul >الفيديوهات الخاصة بالأماكن</ul>
                  <ul >	تصوير اللقاءات والتسجيلات</ul>
                  <ul >	تغطية الفعاليات والأحداث</ul>
                </li>
              <li >
              	التصوير الفوتوغرافي
                <ul >	التصوير الخاص بالأماكن</ul>
                <ul >	تصوير الشخصيات الاعتبارية</ul>
                <ul >	تغطية الفعاليات والأحداث</ul>
              </li>
              <li >
              	تصوير المنتجات
                <ul >	تصوير المنتجات للمبيعات</ul>
                <ul >	تصوير المنتجات للترويج والإعلانات</ul>
                <ul >	تصوير المنتجات الخاصة بالمواقع والبيع المباشر</ul>
              </li>
              </ul>
            )}
            {link === 'FINISHES AND DECORATIONS' && (
              <ul className="service-ul">
                <li>3D design</li>
                <li>Cladding facades and different designs</li>
                <li>Commercial Furniture</li>
                <li>Special and integrated equipment for service places</li>
                <li> Equipment for academies and educational places</li>
              </ul>
            )}
            {link === 'التشطيبات والديكور' && (
              <ul className="service-ul">
                <li>تصميم ثلاثي الأبعاد</li>
                <li>واجهات كلادينج وتصميمات مختلفة</li>
                <li>أثاث تجاري</li>
                <li>	تجهيزات خاصة ومتكاملة للأماكن الخدمية </li>
                <li>	تجهيزات الخاصة بالأكاديميات والأماكن التعليمية </li>
              </ul>
            )}
            {link === 'DIRECT MARKTING' && (
              <ul className="service-ul">
                <li>
                  {' '}
                  Extracting results and setting expectations by analyzing data
                </li>
                <li>Marketing and direct selling plans</li>
                <li>Sales services for the benefit of third parties</li>
                <li>Marketing and contracting representatives</li>
                <li> Direct sales representatives</li>
                <li> outdoor advertisements</li>
                <li> TV advertisements</li>
                <li> Road advertisements</li>
                <li> Magazine advertisements </li>
              </ul>
            )}
            {link === 'التسويق المباشر' && (
              <ul className="service-ul">
                <li> استخراج النتائج وتحديد التوقعات من خلال تحليل البيانات</li>
                <li>خطط التسويق والبيع المباشر</li>
                <li>خدمات البيع لصالح الغير</li>
                <li>مندوبي التسويق والتعاقدات</li>
                <li>مندوبي المبيعات المباشرة</li>
                <li>إعلانات خارجية</li>
                <li>الإعلانات التلفزيونية</li>
                <li>إعلانات الطرق</li>
                <li> إعلانات المجلات </li>
              </ul>
            )}
            {link === 'DIGITAL-MARKTING' && (
              <ul className="service-ul">
                <li> Digital Identity Management</li>
                <li>
                  Managing accounts on social networking sites
                  <ul>facebook</ul>
                  <ul>insta</ul>
                  <ul>tweeter</ul>
                  <ul>YouTube </ul>
                  <ul>tiktok </ul>
                  <ul>snap chat </ul>
                  <ul>Google business</ul>
                </li>
                <li>
                  Managing the accounts of the various selling platforms
                  <ul>Amazon </ul>
                  <ul>Jumia  </ul>
                  <ul>Noon </ul>
                </li>
                <li>Marketing and contracting representatives</li>
                <li> Financing services and managing financial accounts</li>
                <li> And electronic banking: payment - collection</li>
              </ul>
            )}
            {link === 'التسويق الإلكتروني' && (
              <ul className="service-ul">
                <li>إدارة الهوية الرقمية</li>
                <li
                >إدارة الحسابات على مواقع التواصل الاجتماعي
                                  <ul>facebook</ul>
                  <ul>insta</ul>
                  <ul>tweeter</ul>
                  <ul>YouTube </ul>
                  <ul>tiktok </ul>
                  <ul>snap chat </ul>
                  <ul>Google business</ul>
                </li>
                <li>
                  إدارة حسابات منصات البيع المختلفة
                  <ul>جوميا</ul>
                  <ul>امازون</ul>
                  <ul>نون</ul>
                  </li>
                <li>خدمات التمويل وإدارة الحسابات المالية</li>
                <li> والبنكيه الالكترونية: الدفع - التحصيل</li>
              </ul>
            )}
            {link === 'ADVERTISING SERVICES' && (
              <ul className="service-ul">
                <li>
                   Informational films 
                  <ul>Typographic films</ul>
                  <ul>Infographic and animation films</ul>
                  <ul>2D and 3D animation films</ul>
                </li>
                <li>
                   PRODUCTS DESIGNS  
                  <ul> Advertising designs</ul>
                  <ul> designs for packaging and packages</ul>
                  <ul> Advertising designs for products for billboards</ul>
                </li>
                <li>
                  Outdoor advertisements or posters Paper publications
                  
                <ul>Official papers, personal card folders, flyers</ul>
                <ul>Brochures, notebooks, books, receipts and seals</ul>

                </li>
                <li> Promotional gifts</li>
                <li> In-kind gifts, models, medals, pens</li>
                <li> The Uniform</li>

              </ul>
            )}
            {link === 'خدمات الدعاية والاعلان' && (
              <ul className="service-ul">
                <li>
                   أفلام إعلامية
                    
                  <ul>	أفلام التايبوجرافيك </ul>
                  <ul>	وأفلام الإنفوجرافيك والتحريك</ul>
                  <ul>أفلام الرسوم المتحركة ثنائية وثلاثية الأبعاد</ul>
                </li>
                <li>
                تصميمات المنتجات 
                <ul>	التصميمات الدعائية</ul>
                <ul>	والتصميمات الخاصة بالتغليف والعبوات</ul>
                <ul>	التصميمات الإعلانية الخاصة بالمنتجات للوحات</ul>
                </li>
                <li>	الإعلانات الخارجية أو الملصقات</li>
                <li>
                  	 المطبوعات الورقية
                    <ul>	الورق الرسمي، الفولدرات الكروت الشخصية، الفلاير</ul>
                    <ul>	والبروشور، النوت بوك الدفاتر والوصولات والأختام</ul>
                </li>
                <li>	الهدايا الدعانية</li>
                <li>	الهدايا العينية كالمجسمات الميداليات الاقلام</li>
                <li>	الشنط المجات علب المناديل الدروع والتيشيرتات</li>
                <li>	واليونيفورم</li>
              </ul>
            )}
            {link === 'WEBSITES AND APPLICATIONS' && (
              <ul className="service-ul">
                <li> Markets and various electronic stores</li>
                <li>Special and interactive websites</li>
                <li>Various mobile applications ANDROID IOS</li>
              </ul>
            )}
            {link === 'تصميم المواقع والتطبيقات الإلكترونية' && (
              <ul className="service-ul">
                <li> الأسواق والمتاجر الإلكترونية المختلفة</li>
                <li>مواقع خاصة وتفاعلية</li>
                <li>تطبيقات الجوال المختلفة</li>
              </ul>
            )}
            {link === 'ORGANIZING CONFERENCES AND EVENTS' && (
              <ul className="service-ul">
                <li> 3D drawing of the event</li>
                <li>Media graphic for the event</li>
                <li>The content of the program for the event</li>
                <li> Broadcasters and organizers of the event</li>
                <li>Speeches of attendees and speakers</li>
                <li> Invitations to attend</li>
                <li> Filming the event with videos and photos</li>
                <li> Reservation of hotels and airline tickets for guests</li>
                <li>Media content and press file</li>
                <li>Table and hall fixtures and decorations</li>
                <li>TV coverage and carrier channels</li>
                <li> Sound system and lighting fixtures</li>
                <li> The website of the event</li>
                <li> Organizers ID and attendees</li>
                <li> Banners and billboards inside the hall</li>
                <li> Registration program for attendance</li>
                <li> Event publications</li>
                <li> Gifts given to guests</li>
                <li> Display screens</li>
                <li> Advertising campaign before the event</li>
              </ul>
            )}
            {link === 'تنظيم المؤتمرات' && (
              <ul className="service-ul">
                <li> رسم ثلاثي الابعاد خاص بالفعالية</li>
                <li>	الميديا جرافيك الخاصة بالفعالية</li>
                <li>محتوى البرنامج الخاص بالفعالية</li>
                <li>	المذيعين ومنظمين الفعالية</li>
                <li>كلمات الحضور والمتحدثين</li>
                <li>تصوير الفعالية بالفيديو والصور</li>
                <li>	دعوات الحضور</li>
                <li>	الفرق الموسيقية</li>
                <li> حجز الفنادق وتذاكر الطيران للضيوف</li>
                <li>	المحتوي الإعلامي والملف الصحفي</li>
                <li>	تجهيزات وديكورات الطاولات والقاعة</li>
                <li>التغطية التلفزيونية والقنوات الناقلة</li>
                <li>	نظام الصوت وتجهيزات الإضاءة</li>
                <li>	الحملة الإعلانية قبل الفعالية</li>
                <li>	الموقع الالكتروني الخاص بالفعالية</li>
                <li>	ID المنظمين والحضور</li>
                <li>	البنرات واللوحات الدعائية داخل القاعة</li>
                <li>	برنامج التسجيل للحضور</li>
                <li>	المطبوعات الخاصة بالفعالية</li>
                <li>	هدايا المقدمة للضيوف</li>
                <li>	شاشات العرض</li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
