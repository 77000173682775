import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Addlinkgift = () => {
    const [name, setName] = useState('');
 //   const [id, setId] = useState(0);
    let navigt = useNavigate();


    const  formSubmit = (e)=>{
            e.preventDefault()
            axios.post("https://backend.dasta.net/api/auth/addlinkourworkgift",{
                name,
            }).then((data)=>{
                navigt(-1);
            })
        }  
    return(
        
        <>
            <form onSubmit={formSubmit}>
                <label for="fname" >Enter Link:</label>
                <input type="text" id="fname" name="fname" onChange={(e)=>setName(e.target.value)}/>
                <button type='submit' >add</button>
             </form> 
        </>
        
    )
}
export default Addlinkgift;