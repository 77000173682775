import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/8dab4f88a129879185f50e7ed22aa8a2whats-removebg-preview (1).png';
import { useLocation } from 'react-router-dom';




const Getsonsection1  = () => {
    const [open, setIsOpen] = useState(false);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    let { id } = useParams();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation(); 


  
    useEffect(() => {
        section();
        upsection();
  window.scrollTo(0, 0)

     
     }, [pathname]);

     const section = () => {
      fetch(`https://backend.dasta.net/api/auth/getsonbelongtosection/${id}`)
     .then((res)=>res.json())
     .then((data)=>{
       console.log(data);
       setData(data);
       });
     }
     const upsection = () => {
        fetch(`https://backend.dasta.net/api/auth/filtersectionwokautomatic/${id}`)
       .then((res)=>res.json())
       .then((data)=>{
         console.log(data);
         setData1(data);
         });
       }

       function link() {
        //  👇️// redirect to external URL
         
          window.location.replace('https://wa.link/fiolo6');
       
         return null;
       }
    return( 
<>
    {
        data1.map((data1)=>{
            return(
                <>
            <div className="general-background-image gal">
                <div className="back">
                <h2>{data1.name}</h2>
                <br/>
                <Link className="link back-link" to="/">
                    {t('home')}
                </Link>
                </div>
            </div>
                </>
            )
        })
    }
  
  <div className="services" id="services">
      <div className="container services-container">
        {
            data.map((data)=>{
                return(
                    <>   
                        <div className="service" >
                            <br/>
                            <br/>
                            <h2 style={{
                                textAlign:'center',
                                width: '250px',
                                 wordWrap:'break-word',

                                 }}>{data.name}</h2>

                            <div className="gallery" >
                                <img
                                src={"https://backend.dasta.net/brand/"+ data.photo  }

                                alt=""
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    borderRadius: '10px',
                                }}
                                />
                            </div>         
                            <Link
                            className="link"
                            to = {`/showsonsectiondata/${data.id}`}
                            onClick={() => {
                                setIsOpen(false);
                                }}
                                >
                                <h2> {t('Asee')}  </h2>
                            </Link>
        
                        </div>
                    </>
                )
            })
        }
        </div>
         </div>

         <img src={logo} alt='logo' 
            style={{ 
              width :'100px',
              height:'100px',
              position: 'fixed',
              zIndex: '10',
              top: '85%',
              right:'5px',
              cursor:'pointer',
              
             }}
             onClick={()=>link()}
             />  
      
        </>

    )

}
export default Getsonsection1;